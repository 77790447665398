import { createContext, useState } from "react";
import MapDetail from "../components/map/MapDetail";

export const MapContext = createContext({
    map: {},
    setMap: () => {}
});

function MapWrapper() {
    const [map, setMap] = useState({});

    return (
        <MapContext.Provider value={[map, setMap]}>
            <MapDetail />
        </MapContext.Provider>
    )
}

export default MapWrapper;