import React, { useState } from 'react';
import Notification from '../components/Notification';
import { generateId } from '../util/Random';

export const NotificationContext = React.createContext({
    notificationList: [],
    addNotification: () => {},
    removeNotification: () => {},
});

export default function NotificationProvider({ children }) {
    const [notificationList, setnotificationList] = useState([]);

    const contextValue = {
        notificationList,
        addNotification: (message, type) => {
            if (message && type) {
                const newList = [...notificationList, {id: generateId(10), message, type}];
                setnotificationList(newList);
            }
        },
        removeNotification: (id) => {
            if (id && notificationList.length > 0) {
                const newList = notificationList.filter((item) => item.id !== id);
                setnotificationList(newList);
            }
        }
    };

    return (
        <NotificationContext.Provider value={contextValue}>
            <div
                aria-live="assertive"
                className="fixed inset-0 flex flex-col items-end px-4 py-6 pointer-events-none z-50">
                {notificationList.map((item) => (
                    <Notification key={item.id} notification={item}></Notification>
                ))}
            </div>

            { children }
        </NotificationContext.Provider>
    )
}