import { CheckCircleIcon } from '@heroicons/react/24/outline';
import { XCircleIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { Transition } from '@headlessui/react';
import { useContext, useEffect, Fragment, useState } from 'react';
import { NotificationContext } from '../contexts/NotificationContext';

function Notification({ notification }) {
    const { removeNotification } = useContext(NotificationContext);
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(true);
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            removeNotification(notification.id);
        }, 5000);

        return () => {
            clearTimeout(timer);
        }
    }, [removeNotification, notification]);

    function displayType(type) {
        if (type === 'SUCCESS') {
            return <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />
        } else if (type === 'ERROR') {
            return <XCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
        }
    }

    return (
        <Transition
            show={show}
            as={Fragment}
            enter="transition ease-out transform duration-200"
            enterFrom="translate-x-8"
            enterTo="translate-x-0"
        >
            <div className="max-w-sm w-full mb-4 bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="p-4">
                    <div className="flex items-start">
                        <div className="flex-shrink-0">
                            {displayType(notification.type)}
                        </div>

                        <div className="ml-3 w-0 flex-1 pt-0.5">
                            <p className="text-sm font-medium text-gray-900">{notification.message}</p>
                        </div>

                        <div className="ml-4 flex-shrink-0 flex">
                            <button
                                className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500"
                                onClick={() => {
                                    removeNotification(notification.id)
                                }}
                            >
                                <span className="sr-only">Close</span>

                                <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Transition>
    );
}

export default Notification;