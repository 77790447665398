import { useContext, useEffect, useState } from "react";
import Header from "../Header";
import { useParams } from "react-router-dom";
import config from "../../config";
import { AuthContext, getUserToken } from "../../contexts/AuthContext";
import { formatPossibleEmpty } from "../../util/Format";
import Error from "../Error";
import { Admin1Context } from "../../pages/Admin1Wrapper";
import Admin1Translations from "./AdminTranslations";
import { wrapFetch } from "../../util/Hmac";

function Admin1Detail() {
    const { id } = useParams();
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [admin1, setAdmin1] = useContext(Admin1Context);
    const authValue = useContext(AuthContext);

    useEffect(() => {
        document.title = `GeoCMS\u00ae | ${admin1.name}`;
    }, [admin1]);

    useEffect(() => {
        async function getData() {
            try {
                const token = await getUserToken();
                const result = await wrapFetch(`${config.backend_url_v2}/v3/cms/admin/${id.toLowerCase()}`, {
                    headers: {
                        'X-Access-Token': token
                    }
                });

                setAdmin1(result);
                setIsLoaded(true);
            } catch (error) {
                setError(error);
            } finally {
                setIsLoaded(true);
            }
        }

        if (authValue && authValue.user) {
            getData();
        }
    }, [id, setAdmin1, authValue]);

    if (error) {
        return (
            <div className="Admin 1">
                <Header heading="Admin 1" />

                <Error error={error} />
            </div>
        )
    } else if (!isLoaded) {
        return (
            <div className="Admin 1">
                <Header heading="Admin 1" loading={true} />

                <div className="flex flex-col max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                    <div className="bg-gray-200 w-full animate-pulse h-64 rounded-lg mb-4"></div>
                </div>
            </div>
        )
    } else if (admin1) {
        return (
            <div className="Admin 2">
                <Header heading={admin1.name} />

                <div className="flex flex-col max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                    <section className="bg-white shadow overflow-hidden sm:rounded-lg mt-4">
                        <div className="px-4 py-5 sm:px-6">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">Admin details</h3>
                        </div>

                        <div className="border-t border-gray-200">
                            <dl>
                                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Code
                                    </dt>

                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        <span>{formatPossibleEmpty(admin1.code)}</span>
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </section>

                    <Admin1Translations />
                </div>
            </div>
        )
    }
}

export default Admin1Detail;