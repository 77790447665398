import { Fragment, useContext } from "react";
import { formatPossibleEmpty } from "../../util/Format";
import { CountryContext } from "../../pages/CountryWrapper";

function CountryDetails() {
    const [country] = useContext(CountryContext);

    return (
        <Fragment>
            <div className="bg-white shadow sm:rounded-lg mt-4">
                <div className="flex justify-between px-4 py-5 sm:px-6">
                    <div className="flex items-center">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Country details</h3>
                    </div>
                </div>

                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                                Country code
                            </dt>

                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <span>{formatPossibleEmpty(country.code)}</span>
                            </dd>
                        </div>
                    </dl>
                </div>
            </div>
        </Fragment>
    )
}

export default CountryDetails;