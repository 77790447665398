import { Switch } from "@headlessui/react";
import { useContext, useEffect, useState } from "react";
import { LocationContext } from "../../pages/LocationWrapper";
import { classNames } from "../../util/Format";
import EditRow from "../EditRow";
import LocationAlternateLine from "./LocationAlternateLine";
import LocationAlternateNew from "./LocationAlternateNew";

function LocationAlternate() {
    const [location] = useContext(LocationContext);
    const [filteredAlternates, setFilteredAlternates] = useState([]);
    const [filter, setFilter] = useState(true);
    const [editing, setEditing] = useState(false);
    const alternates = location.alternates;

    useEffect(() => {
        setFilteredAlternates(alternates);
    }, [alternates]);

    useEffect(() => {
        if (filter) {
            const newList = alternates.filter((alternate) => {
                return ['', 'nl', 'en', 'fy', 'de', 'fr', 'it', 'es', 'pl', 'hu', 'no', 'sv'].includes(alternate.language_code);
            });

            if (newList.length > 0) {
                setFilteredAlternates(newList);
            } else {
                setFilter(false);
            }
        } else {
            setFilteredAlternates(alternates);
        }
    }, [alternates, filter]);

    if (filteredAlternates) {
        return (
            <section className="bg-white shadow overflow-hidden sm:rounded-lg mt-4">
                <div className="flex items-center justify-between px-4 py-5 sm:px-6">
                    {(filteredAlternates.length > 0) && <div className="flex">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Location alternate names</h3>

                        <span className="ml-6 mr-2 text-gray-600 text-sm">Show relevant only</span>

                        <Switch
                            checked={filter}
                            onChange={setFilter}
                            className={classNames(
                                filter ? 'bg-blue-600' : 'bg-gray-200',
                                'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                            )}
                        >
                            <span
                                aria-hidden="true"
                                className={classNames(
                                    filter ? 'translate-x-5' : 'translate-x-0',
                                    'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                )}
                            />
                        </Switch>
                    </div>}

                    {(filteredAlternates.length === 0) && <h3 className="text-lg leading-6 font-medium text-gray-900">No alternate names found</h3>}

                    <EditRow
                        editState={editing}
                        buttonText="Done"
                        save={() => {setEditing(false)}}
                        editing={(state) => {setEditing(state)}} />
                </div>

                {(filteredAlternates.length > 0) && <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-white">
                        <tr>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Name
                            </th>

                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Type
                            </th>

                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Preferred
                            </th>

                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Short
                            </th>

                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Colloquial
                            </th>

                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Historic
                            </th>

                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Show
                            </th>

                            {editing && <th></th>}
                        </tr>
                    </thead>

                    <tbody className="bg-white divide-y divide-gray-200">
                        {filteredAlternates.map(alternate => (
                            <LocationAlternateLine key={alternate.id} alternate={alternate} editing={editing} />
                        ))}
                    </tbody>
                </table>}

                {editing && <LocationAlternateNew />}
            </section>
        )
    }
}

export default LocationAlternate;