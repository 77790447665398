import { useContext, useRef } from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import { centerGroup, plotCitiesWithLink, zoomGroup } from "../../util/Map";
import { ListContext } from "../../pages/ListWrapper";

function ListMap() {
    const [list] = useContext(ListContext);
    const map = useRef(null);
    const latlons = list.locations.map((location) => {
        return {latitude: location.latitude, longitude: location.longitude};
    });

    const center = centerGroup(latlons);

    // Get width of the map container
    const zoom = zoomGroup(latlons);

    if (!list) {
        return null;
    }

    if (list) {
        return (
            <section className="bg-white shadow overflow-hidden rounded-lg flex full-w flex-col my-6">
                <div className="flex items-center justify-between px-4 py-5 sm:px-6">
                    <div>
                        <div className="flex items-center">
                            <h2 className="text-2xl font-bold text-gray-900">
                                Map
                            </h2>
                        </div>
                    </div>
                </div>

                <div className="block h-half z-0">
                    <MapContainer ref={map} center={[center.latitude, center.longitude]} zoom={zoom}>
                        <TileLayer
                            attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors - &copy; <a href="https://maptiler.com/copyright">MapTiler</a>'
                            url="https://maps.meteoplaza.com/styles/topo/{z}/{x}/{y}.png" />

                        {plotCitiesWithLink(list.locations)}
                    </MapContainer>
                </div>
            </section>
        )
    }
}

export default ListMap;