import React, { useState } from 'react';
import Modal from '../components/Modal';

export const ModalContext = React.createContext({
    modal: {},
    openModal: () => {},
    closeModal: () => {},
});

export default function ModalProvider({ children }) {
    const [modal, setModal] = useState(null);

    const contextValue = {
        modal,
        openModal: (title, body, cancelText, actionText, actionFunction) => {
            if (title && body && cancelText && actionText && actionFunction) {
                setModal({ title, body, cancelText, actionText, actionFunction });
            }
        },
        closeModal: () => {
            setModal(null);
        }
    };

    return (
        <ModalContext.Provider value={contextValue}>
            {modal && <div
                aria-live="assertive"
                className="modals fixed inset-0 flex flex-col items-end px-4 py-6 pointer-events-none z-50">
                <Modal
                    title={modal.title}
                    body={modal.body}
                    cancelText={modal.cancelText}
                    actionText={modal.actionText}
                    actionFunction={modal.actionFunction} />
            </div>}

            {children}
        </ModalContext.Provider>
    )
}