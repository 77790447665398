import { createContext, useState } from "react";
import POIDetail from "../components/poi/POIDetail";

export const POIContext = createContext({
    poi: {},
    setPOI: () => {}
});

function POIWrapper() {
    const [poi, setPOI] = useState({});

    return (
        <POIContext.Provider value={[poi, setPOI]}>
            <POIDetail />
        </POIContext.Provider>
    )
}

export default POIWrapper;