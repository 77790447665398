import { Fragment, useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Menu } from '@headlessui/react';
import { AuthContext } from '../contexts/AuthContext';
import { logout } from "../contexts/AuthContext";

function Header(props) {
    const authValue = useContext(AuthContext);
    const loading = props.loading || false;

    const activeClass = "bg-gray-600 text-gray-100 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium";
    const inActiveClass = "text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium";

    function loadUser() {
        if (authValue && authValue.user) {
            return (
                <Menu as="div" className="ml-3 relative">
                    <Menu.Button className="flex items-center rounded-md">
                        <span className="text-white font-sans font-bold text-center mr-4">{authValue.user.displayName}</span>

                        <img loading="lazy" className="h-8 w-8 rounded-full" rel="no-referrer"
                            src={authValue.user.photoURL}
                            alt={authValue.user.displayName} />
                    </Menu.Button>

                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                            <span onClick={() => logout()} className="block px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100">Log out</span>
                        </Menu.Item>
                    </Menu.Items>
                </Menu>
            )
        } else {
            return (
                <div className="bg-gray-700 w-40 animate-pulse h-8 rounded-lg"></div>
            )
        }
    }

    return (
        <Fragment>
            <nav className="bg-gray-800">
                <div className="flex max-w-7xl mx-auto px-8 h-16 items-center justify-between">
                    <div className="flex flex-1 items-center items-stretch justify-start">
                        <div className="flex items-center">
                            <Link to="/">
                                <img className="block h-8 w-auto" src="/logo-c.svg" alt="Geo CMS" />
                            </Link>
                        </div>

                        <div className="flex ml-6">
                            <div className="flex space-x-4">
                                <NavLink to="/search"
                                    className={({ isActive }) => isActive  ? activeClass : inActiveClass}>Search</NavLink>
                                <NavLink to="/continents"
                                    className={({ isActive }) => isActive  ? activeClass : inActiveClass}>Continents</NavLink>
                                <NavLink to="/countries"
                                    className={({ isActive }) => isActive  ? activeClass : inActiveClass}>Countries</NavLink>
                                <NavLink to="/regions"
                                    className={({ isActive }) => isActive  ? activeClass : inActiveClass}>Regions</NavLink>
                                <NavLink to="/pois"
                                    className={({ isActive }) => isActive  ? activeClass : inActiveClass}>POI's</NavLink>
                                <NavLink to="/events"
                                    className={({ isActive }) => isActive  ? activeClass : inActiveClass}>Events</NavLink>
                                <NavLink to="/lists"
                                    className={({ isActive }) => isActive  ? activeClass : inActiveClass}>Lists&trade;</NavLink>
                                <NavLink to="/maps"
                                    className={({ isActive }) => isActive  ? activeClass : inActiveClass}>Maps</NavLink>
                            </div>
                        </div>
                    </div>

                    <div className="flex object-right ml-3 items-center justify-center">
                        {loadUser()}
                    </div>
                </div>
            </nav>

            <header className="bg-white shadow">
                <div className="flex items-center justify-between max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                    {!loading && <h1 className="text-3xl font-bold text-gray-900">
                        {props.heading}
                    </h1>}

                    {loading && <div className="bg-gray-300 w-40 animate-pulse h-8 rounded-lg"></div>}

                    <div className="ml-auto">
                        {props.children}
                    </div>
                </div>
            </header>
        </Fragment>
    );
}

export default Header;
