import config from "../config";

export function addToLocalstorage(storageKey, object) {
    localStorage.setItem(storageKey, JSON.stringify(object));
}

export function getFromLocalstorage(storageKey) {
    const data = localStorage.getItem(storageKey);
    return JSON.parse(data);
}

export function removeFromLocalstorage(storageKey) {
    localStorage.removeItem(storageKey);
}

export function addToLocationsList(location) {
    const key = config.storage_key;
    const locationsList = getFromLocalstorage(key);

    if (locationsList && locationsList.length > 0 && location.geoname_id) {
        const index = locationsList.findIndex((item) => item.geoname_id === location.geoname_id);

        let newList;
        if (index > -1) {
            newList = [...locationsList];
            newList[index].country_name = location.country_name;
            newList[index].name = location.name;
            newList[index].population = location.population;
            newList[index].visited = new Date();

            // Move item to top of the list
            newList.unshift(newList.splice(index, 1)[0]);
        } else {
            newList = [{...location, visited: new Date()}, ...locationsList];
        }

        if (locationsList.length > 10) {
            newList = newList.slice(0, 10);
        }

        addToLocalstorage(key, newList);
    } else if (location.geoname_id) {
        addToLocalstorage(key, [{...location, visited: new Date()}]);
    }
}