import { ArrowPathIcon, MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { Fragment, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import config from "../../config";
import { getUserToken } from "../../contexts/AuthContext";
import { NotificationContext } from "../../contexts/NotificationContext";
import { ListContext } from "../../pages/ListWrapper";
import { latinize } from "../../util/Format";
import EditRow from "../EditRow";
import ListSearchLine from "./ListSearchLine";

function ListCityList() {
    const [list, setList] = useContext(ListContext);
    const { addNotification } = useContext(NotificationContext);
    const [editing, setEditing] = useState(false);
    const [query, setQuery] = useState('');
    const [input, setInput] = useState('');
    const [loading, setLoading] = useState(false);
    const [searchResults, setSearchResults] = useState([]);

    function searchInput(event) {
        event.preventDefault();
        setQuery(input);
    };

    async function deleteCity(city) {
        setLoading(true);

        const token = await getUserToken();
        const res = await fetch(`${config.backend_url_v2}/v3/cms/list/location`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                geoname_id: city.geoname_id,
                list_id: Number(list.id)
            })
        });

        if (res.status === 204) {
            const filteredList = list.locations.filter((item) => item.geoname_id !== city.geoname_id);
            let copy = {...list};
            copy.locations = filteredList;

            setList(copy);
            setLoading(false);
            addNotification('City was removed from the list successfully', 'SUCCESS');
        }
    }

    useEffect(() => {
        async function getData() {
            try {
                setLoading(true);
                let url;
                const cleanedQuery = latinize(query);
                url = `${config.backend_url_v2}/v2/Wol/NL/Geos/search?word=${cleanedQuery}`;

                const res = await fetch(url);

                if (res.status === 200) {
                    const result = await res.json();

                    setSearchResults(result);
                    setLoading(false);
                }
            } catch (error) {
                console.log(error);
            }
        }

        if (query && query.length > 0) {
            getData();
        }
    }, [query]);

    function addNewCity() {
        if (editing) {
            return (
                <Fragment>
                    <div className="flex flex-col border-t max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-6">
                        <div className="mt-1 flex rounded-md shadow-sm">
                            <form onSubmit={searchInput} className="flex relative items-stretch flex-grow focus-within:z-10">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </div>

                                <input
                                    value={input}
                                    onInput={event => setInput(event.target.value)}
                                    type="search"
                                    name="search"
                                    id="search"
                                    className="focus:ring-blue-500 focus:border-blue-500 block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300"
                                    placeholder="Search for location name"
                                />

                                <button
                                    type="submit"
                                    disabled={loading}
                                    className="-ml-px inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-white hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500">

                                    {loading && <ArrowPathIcon className="animate-spin mr-1 h-4 w-4" />}
                                    <span>Search</span>
                                </button>
                            </form>
                        </div>
                    </div>

                    {searchResults.length > 0 && <div className="px-6 pb-6">
                        <div className="flex items-center space-x-3 mb-2">
                            <h2>Search results</h2>
                        </div>

                        <div className="bg-white border border-gray-300 overflow-hidden rounded-md">
                            <ul className="divide-y divide-gray-300">
                                {searchResults.map((item) => (
                                    <ListSearchLine key={item.geonames_id} item={item} />
                                ))}
                            </ul>
                        </div>
                    </div>
                    }

                    {searchResults.length === 0 && query.length > 0 && <div className="px-6 pb-4">
                        <p>Nothing found for "{query}"</p>
                    </div>}
                </Fragment>
            )
        }
    }

    return (
        <section className="bg-white shadow overflow-hidden sm:rounded-lg mt-4">
            <div className="flex items-center justify-between px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Cities in this list</h3>

                <EditRow
                    cancel={true}
                    buttonText="Done"
                    editState={editing}
                    save={() => { setEditing(false) }}
                    editing={(state) => { setEditing(state) }} />
            </div>

            <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-white">
                    <tr>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Geonames ID
                        </th>

                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Name
                        </th>

                        {editing && <th scope="col"></th>}
                    </tr>
                </thead>

                <tbody className="bg-white divide-y divide-gray-200">
                    {list.locations.map(city => (
                        <tr key={city.geoname_id}>
                            <td className="px-6 py-4 whitespace-nowrap">
                                {city.geoname_id}
                            </td>

                            <td className="px-6 py-4 whitespace-nowrap">
                                <Link className="underline cursor-pointer text-blue-800 hover:text-blue-900" to={`/locations/${city.geoname_id}`}>{city.name || city.location_name}</Link>
                            </td>

                            {editing && <td className="flex px-6 py-4">
                                <button
                                    onClick={() => deleteCity(city)}
                                    type="button"
                                    className="ml-auto inline-flex items-center px-2 py-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-300">
                                    Remove
                                    <XMarkIcon className="ml-1 h-5 w-5" />
                                </button>
                            </td>}
                        </tr>
                    ))}
                </tbody>
            </table>

            {addNewCity()}
        </section>
    )
}

export default ListCityList;