import { Fragment } from "react";
import { CircleMarker, Popup, useMapEvent } from "react-leaflet";
import { Link } from "react-router-dom";
import { formatLargeNumber } from "./Format";

function displayName(item) {
    if (item.name) {
        return item.name;
    } else if (item.location_name) {
        return item.location_name;
    }
}

function displayPopulation(item) {
    if (item.population) {
        return `(Pop ${formatLargeNumber(item.population)})`;
    }
}

export function plotCitiesWithLink(results) {
    if (results && results.length > 0) {
        return (
            <Fragment>
                {results.map((result) => (
                    <CircleMarker
                        key={result.geoname_id}
                        center={[result.latitude, result.longitude]}
                        pathOptions={{color: (result.weeronline_id ? '#0282eb' : 'red'), opacity: (result.population === '0' ? 0.3 : 1)}}
                        radius={5}>
                            <Popup>
                                <Link className="underline cursor-pointer text-blue-800 hover:text-blue-900" to={`/locations/${result.geonameid}`}>{displayName(result)}</Link>
                                <span className="ml-2 text-gray-400">{displayPopulation(result)}</span>
                            </Popup>
                    </CircleMarker>
                ))}
            </Fragment>
        )
    }
}

export function LeafletHandler(props) {
    const map = useMapEvent({
        click(event) {
            props.newCenter(event.latlng);
        },
        zoomend() {
            props.newZoom(map.getZoom())
        }
    });

    return null;
}

/**
 * Given a list of lat longs return the center lat lon
 */
export function centerGroup(points) {
    if (points && points.length > 0) {
        const lat = points.reduce((sum, point) => sum + point.latitude, 0) / points.length;
        const lon = points.reduce((sum, point) => sum + point.longitude, 0) / points.length;

        return {latitude: lat, longitude: lon};
    }
}

/**
 * Given a list of lat longs, return the zoom level
 */
export function zoomGroup(points) {
    if (points && points.length > 0) {
        const latitudes = points.map((point) => point.latitude);
        const longitudes = points.map((point) => point.longitude);

        const latMin = Math.min(...latitudes);
        const latMax = Math.max(...latitudes);
        const lonMin = Math.min(...longitudes);
        const lonMax = Math.max(...longitudes);

        const latDiff = latMax - latMin;
        const lonDiff = lonMax - lonMin;

        const latZoom = Math.floor(Math.log2((360 * 2) / (256 * 0.9 / latDiff)));
        const lonZoom = Math.floor(Math.log2((360 * 2) / (256 * 0.9 / lonDiff)));

        return Math.min(latZoom, lonZoom);
    }
}

/**
 * Given two objects with latitude and longitude this function returns the distance in km
 */
export function calculateDistance(coordinateA, coordinateB) {
    const p = 0.017453292519943295; // Math.PI / 180
    const c = Math.cos;
    const a = 0.5 - c((coordinateB.latitude - coordinateA.latitude) * p)/2 +
            c(coordinateA.latitude * p) * c(coordinateB.latitude * p) *
            (1 - c((coordinateB.longitude - coordinateA.longitude) * p))/2;

    return parseFloat(12742 * Math.asin(Math.sqrt(a))).toFixed(1);
}