import { CheckIcon, PencilIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Fragment, useContext, useEffect, useState } from "react";
import config from "../../config";
import { getUserToken } from "../../contexts/AuthContext";
import { NotificationContext } from "../../contexts/NotificationContext";
import { RegionContext } from "../../pages/RegionWrapper";

function RegionTranslationLine(props) {
    const [region, setRegion] = useContext(RegionContext);
    const { addNotification } = useContext(NotificationContext);
    const [editing, setEditing] = useState(false);
    const [name, setName] = useState('');
    const [languageCode, setLanguageCode] = useState('');

    useEffect(() => {
        if (props.translation.name && props.translation.language_code) {
            setName(props.translation.name);
            setLanguageCode(props.translation.language_code);
        }
    }, [props]);

    useEffect(() => {
        if (!props.editing) {
            setEditing(false);
        }
    }, [props.editing]);

    function editTranslation(event) {
        event.preventDefault();
        editRegionTranslation();
    };

    async function editRegionTranslation() {
        const token = await getUserToken();
        const res = await fetch(`${config.backend_url_v2}/v2/Region/Translation/${Number(props.translation.id)}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                name,
                language_code: languageCode,
                region_id: Number(region.id)
            })
        });

        if (res.status === 200) {
            let copy = { ...region };
            const index = copy.translations.findIndex((item) => item.id === props.translation.id);

            copy.translations[index].name = name;
            copy.translations[index].language_code = languageCode;

            setEditing(false);
            setRegion(copy);
            addNotification('Translation was editted successfully', 'SUCCESS');
        }
    }

    async function deleteTranslation() {
        const token = await getUserToken();
        const res = await fetch(`${config.backend_url_v2}/v2/Region/Translation/${Number(props.translation.id)}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        if (res.status === 200) {
            const filteredList = region.translations.filter((item) => item.id !== props.translation.id);
            let copy = { ...region };
            copy.translations = filteredList;

            setRegion(copy);
            addNotification('Translation was removed from the region successfully', 'SUCCESS');
        }
    }

    return (
        <div className="flex justify-between center-items bg-white px-6 py-4">
            <div className="flex w-full">
                {!editing && <Fragment>
                    <div className="w-1/6 pl-3 text-sm font-medium text-gray-500">
                        {props.translation.language_code}
                    </div>

                    <div className="w-full pl-3 mt-1 ml-8 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {props.translation.name}
                    </div>
                </Fragment>}

                {(props.editing && !editing) && <div className="flex ml-auto">
                    <button
                        onClick={() => { setEditing(true) }}
                        type="button"
                        className="inline-flex items-center px-2 py-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700">
                        Edit
                        <PencilIcon className="ml-1 h-4 w-4"></PencilIcon>
                    </button>

                    <button
                        onClick={deleteTranslation}
                        type="button"
                        className="ml-2 inline-flex items-center px-2 py-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-300">
                        Remove
                        <XMarkIcon className="ml-1 h-5 w-5" />
                    </button>
                </div>}

                {(editing && props.editing) && <div className="flex w-full">
                    <form onSubmit={editTranslation} className="flex items-stretch flex-grow focus-within:z-10">
                        <input
                            value={languageCode}
                            onChange={(e) => setLanguageCode(e.target.value)}
                            type="text"
                            name="language-code"
                            id="language-code"
                            className="focus:ring-blue-500 focus:border-blue-500 block w-1/6 rounded-none rounded-md sm:text-sm border-gray-300"
                            placeholder="nl or en_US" />

                        <input
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            type="text"
                            name="name"
                            id="name"
                            className="ml-4 focus:ring-blue-500 focus:border-blue-500 block w-full rounded-none rounded-md sm:text-sm border-gray-300"
                            placeholder="Paris or Berlijn" />

                        <button
                            type="submit"
                            className="ml-4 inline-flex items-center px-2 py-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700">
                            Save
                            <CheckIcon className="ml-1 h-4 w-4"></CheckIcon>
                        </button>
                    </form>
                </div>}
            </div>
        </div>
    )
}

export default RegionTranslationLine;