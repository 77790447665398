import { createContext, useState } from "react";
import Admin1Detail from "../components/admin/Admin1Detail";

export const Admin1Context = createContext({
    admin1: {},
    setAdmin1: () => {}
});

function Admin1Wrapper() {
    const [admin1, setAdmin1] = useState({});

    return (
        <Admin1Context.Provider value={[admin1, setAdmin1]}>
            <Admin1Detail />
        </Admin1Context.Provider>
    )
}

export default Admin1Wrapper;