import { createContext, useState } from "react";
import ListDetail from "../components/list/ListDetail";

export const ListContext = createContext({
    list: {},
    setList: () => {}
});

function ListWrapper() {
    const [list, setList] = useState({});

    return (
        <ListContext.Provider value={[list, setList]}>
            <ListDetail></ListDetail>
        </ListContext.Provider>
    )
}

export default ListWrapper;