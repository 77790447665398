import { useContext, useState } from "react";
import EditRow from "../EditRow";
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { RegionContext } from "../../pages/RegionWrapper";
import { Link } from "react-router-dom";
import RegionListEdit from "./RegionListEdit";

function RegionLists() {
    const [region] = useContext(RegionContext);
    const [editing, setEditing] = useState(false);
    const [loading] = useState(false);

    function showName(item) {
        if (item.name) {
            return item.name;
        } else if (item.location_name) {
            return item.location_name;
        }
    }

    if (region) {
        return (
            <section className="mt-4 bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="flex items-center justify-between px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Region lists</h3>

                    <EditRow
                        loading={loading}
                        editState={editing}
                        buttonText="Done"
                        save={() => {setEditing(false)}}
                        editing={(state) => {setEditing(state)}} />
                </div>

                <div className="flex flex-col box-border border-t border-gray-200 py-2">
                    {!editing && region.region_lists.map(list => (
                        <Disclosure key={list.list_id}>
                            <Disclosure.Button className="flex p-4 bg-blue-100 mx-6 my-2 rounded items-center">
                                <div>
                                    <Link className="underline cursor-pointer text-blue-800 hover:text-blue-900" to={`/lists/${list.list_id}`}>{list.list_name}</Link>

                                    {list.default_list && <span className="ml-2 inline-flex items-center rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-700">default</span>}
                                </div>

                                <ChevronDownIcon className="ml-auto h-5 w-5 text-gray-400" />
                            </Disclosure.Button>

                            <Disclosure.Panel>
                                <div className="flex flex-col border mx-6 p-4">
                                    <table className="min-w-full divide-y divide-gray-200">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Geonames ID
                                                </th>

                                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Name
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody className="divide-y divide-gray-200">
                                            {list.locations.length && list.locations.map(city => (
                                                <tr key={city.geoname_id}>
                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        {city.geoname_id}
                                                    </td>

                                                    <td className="px-6 py-4 whitespace-nowrap">
                                                        <Link className="underline cursor-pointer text-blue-800 hover:text-blue-900" to={`/locations/${city.geoname_id}`}>{showName(city)}</Link>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </Disclosure.Panel>
                        </Disclosure>
                    ))}

                    {editing &&
                        <RegionListEdit />
                    }
                </div>

            </section>
        )
    } else {
        return null
    }
}

export default RegionLists;