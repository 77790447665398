import { CheckIcon, PlusIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { useContext, useState } from "react";
import config from "../../config";
import { getUserToken } from "../../contexts/AuthContext";
import { NotificationContext } from "../../contexts/NotificationContext";
import { ListContext } from "../../pages/ListWrapper";
import { formatPossibleEmpty } from "../../util/Format";

function ListSearchLine(props) {
    const item = props.item;

    const [list, setList] = useContext(ListContext);
    const { addNotification } = useContext(NotificationContext);
    const [loading, setLoading] = useState(false);

    async function addCity(city) {
        setLoading(true);

        const token = await getUserToken();
        const res = await fetch(`${config.backend_url_v2}/v2/List/Location`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                geoname_id: city.geoname_id,
                list_id: Number(list.id)
            })
        });

        console.log(city);

        if (res.status === 204) {
            const newList = [...list.locations, {
                geoname_id: city.geoname_id,
                location_id: city.location_id,
                location_name: city.location_name,
                latitude: city.latitude,
                longitude: city.longitude
            }];

            let copy = {...list};
            copy.locations = newList;

            setList(copy);
            setLoading(false);
            addNotification('City was added to the list successfully', 'SUCCESS');
        }
    }

    function isCityInList(geoname_id) {
        return list.locations.find((item) => item.geoname_id === geoname_id);
    }

    function buttonShow() {
        if (isCityInList(item.geoname_id) && item.location_id) {
            return (
                <button
                    disabled
                    type="button"
                    className="inline-flex items-center px-2 py-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-300">
                    Added
                    <CheckIcon className="ml-1 h-5 w-5"></CheckIcon>
                </button>
            )
        } else if (!item.location_id) {
            return (
                <button
                    disabled
                    type="button"
                    className="inline-flex items-center px-2 py-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-300">
                    Not mapped to Weeronline
                    <XMarkIcon className="ml-1 h-5 w-5" />
                </button>
            )
        } else {
            return (
                <button
                    disabled={loading}
                    onClick={() => addCity(item)}
                    type="button"
                    className="inline-flex items-center px-2 py-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                    Add
                    <PlusIcon className="ml-1 h-5 w-5"></PlusIcon>
                </button>
            )
        }
    }

    return (
        <li className="p-4">
            <div className="flex items-center justify-between">
                <div className="flex items-center">
                    <span>{item.location_name}</span>

                    <p className="ml-4 text-sm font-medium text-gray-500 truncate">{item.country_name} - {formatPossibleEmpty(item.adm1_full)} - {formatPossibleEmpty(item.adm2_full)}</p>
                </div>

                {buttonShow()}
            </div>
        </li>
    )
}

export default ListSearchLine;