import { useContext, useState } from "react";
import EditRow from "../EditRow";
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { CountryContext } from "../../pages/CountryWrapper";
import { Link } from "react-router-dom";
import CountryMapEdit from "./CountryMapEdit";

function CountryMaps() {
    const [country] = useContext(CountryContext);
    const [editing, setEditing] = useState(false);
    const [loading] = useState(false);

    if (country) {
        return (
            <section className="mt-4 bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="flex items-center justify-between px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Country maps</h3>

                    <EditRow
                        loading={loading}
                        editState={editing}
                        buttonText="Done"
                        save={() => {setEditing(false)}}
                        editing={(state) => {setEditing(state)}} />
                </div>

                <div className="flex flex-col box-border border-t border-gray-200 py-2">
                    {!editing && country.country_maps.map(map => (
                        <Disclosure key={map.map_id}>
                            <Disclosure.Button className="flex p-4 bg-blue-100 mx-6 my-2 rounded items-center">
                                <div>
                                    <Link className="underline cursor-pointer text-blue-800 hover:text-blue-900" to={`/maps/${map.map_id}`}>{map.map_name}</Link>
                                </div>

                                <ChevronDownIcon className="ml-auto h-5 w-5 text-gray-400" />
                            </Disclosure.Button>
                        </Disclosure>
                    ))}

                    {editing &&
                        <CountryMapEdit />
                    }
                </div>

            </section>
        )
    } else {
        return null
    }
}

export default CountryMaps;