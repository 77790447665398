import { GlobeEuropeAfricaIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header";
import config from "../config";
import { formatLargeNumber } from "../util/Format";
import { getFromLocalstorage, removeFromLocalstorage } from "../util/Localstorage";

function Home() {
    const [locations, setLocations] = useState([]);

    useEffect(() => {
        document.title = `GeoCMS\u00ae | Home`;
        setLocations(getFromLocalstorage(config.storage_key));
    }, [setLocations]);

    function clearRecent() {
        removeFromLocalstorage(config.storage_key);
        setLocations([]);
    }

    function setLocationList() {
        if (locations && locations.length > 0) {
            return (
                locations.map(item => (
                    <div key={item.geoname_id} className="bg-white px-2 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                            <Link to={`/locations/${item.geoname_id}`} className="underline cursor-pointer text-blue-800 hover:text-blue-900">{item.name}</Link>
                        </dt>

                        <dd className="mt-1 flex items-center text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            <span className="inline-flex flex-shrink-0 items-center px-2 py-0.5 text-gray-800 text-xs font-medium bg-gray-100 rounded-full">
                                <GlobeEuropeAfricaIcon className="w-4 h-4 mr-1" />
                                {item.country_name}
                            </span>

                            <span className="ml-2 flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full">
                                Pop {formatLargeNumber(item.population)}
                            </span>
                        </dd>
                    </div>
                ))
            )
        } else {
            return (
                <span className="inline-block text-gray-600 p-6">No locations visited yet</span>
            )
        }
    }

    return (
        <div className="Home">
            <Header heading="Home" />

            <div className="flex flex-col max-w-7xl mx-auto pb-6 px-4 sm:px-6 lg:px-8">
                <div className="bg-white shadow overflow-hidden sm:rounded-lg sm:w-full md:w-1/2 mt-4">
                    <div className="flex items-center px-4 py-5 sm:px-6">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Last visited locations</h3>
                        <button onClick={() => clearRecent()} className="ml-auto inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50">Clear</button>
                    </div>

                    <div className="border-t border-gray-200">
                        <dl>
                            {setLocationList()}
                        </dl>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home;