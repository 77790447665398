import { XCircleIcon } from "@heroicons/react/24/solid";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Error from "../components/Error";
import Header from "../components/Header";
import PopoverMap from "../components/PopoverMap";
import config from "../config";
import { AuthContext } from "../contexts/AuthContext";
import { latinize } from "../util/Format";
import { wrapFetch } from "../util/Hmac";
import { generateId } from "../util/Random";

function Countries() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [countries, setCountries] = useState([]);
    const [filter, setFilter] = useState('');
    const [filteredCountries, setFilteredCountries] = useState([]);
    const authValue = useContext(AuthContext);

    useEffect(() => {
        document.title = `GeoCMS\u00ae | Countries`;
    }, []);

    useEffect(() => {
        setFilteredCountries(countries);
    }, [countries]);

    useEffect(() => {
        const newList = countries.filter((country) => latinize(country.name_default).toLowerCase().includes(filter.toLowerCase()));
        setFilteredCountries(newList);
    }, [countries, filter]);

    useEffect(() => {
        async function getData() {
            try {
                const result = await wrapFetch(`${config.backend_url_v3}/v3/cms/country?cb=${generateId(6)}`);

                setCountries(result);
                setIsLoaded(true);
            } catch (error) {
                setError(error);
                setIsLoaded(true);
            }
        }

        if (authValue && authValue.user) {
            getData();
        }
    }, [authValue]);

    if (error) {
        return (
            <div className="Countries">
                <Header heading="Countries" />

                <Error error={error} />
            </div>
        )
    } else if (!isLoaded) {
        return (
            <div className="Countries">
                <Header heading="Countries" />

                <div className="flex flex-col max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                    <div className="bg-gray-300 w-full animate-pulse h-16 rounded-lg mb-4"></div>
                    <div className="bg-gray-300 w-full animate-pulse h-16 rounded-lg"></div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="Countries">
                <Header heading="Countries" />

                <div className="flex flex-col max-w-7xl mx-auto pb-6 px-4 sm:px-6 lg:px-8">
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                            <div className="flex py-6">
                                <div className="mt-1 relative rounded-md shadow-sm w-1/3">
                                    <input
                                        type="text"
                                        value={filter}
                                        className="block border-gray-300 w-full pr-10 focus:outline-none sm:text-sm rounded-md"
                                        placeholder={`Filter ${countries.length} countries`} onInput={(event) => setFilter(event.target.value || '')}/>

                                    {filter && <div className="absolute inset-y-0 right-0 pr-2 flex items-center cursor-pointer" onClick={() => setFilter('')}>
                                        <XCircleIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                    </div>}
                                </div>
                            </div>

                            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-white">
                                        <tr>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Name (EN)
                                            </th>

                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Code
                                            </th>

                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Continent
                                            </th>

                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Center
                                            </th>

                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Zoom
                                            </th>

                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Translations
                                            </th>

                                            <th scope="col" className="relative px-6 py-3">
                                                <span className="sr-only">Edit</span>
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {filteredCountries.map(country => (
                                            <tr key={country.geonames_id}>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <Link to={`/countries/${country.geonames_id}`} className="underline cursor-pointer">{country.name_default}</Link>
                                                </td>

                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    {country.code}
                                                </td>

                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <Link className="underline cursor-pointer text-blue-800 hover:text-blue-900" to={`/continents/${country.continent_id}`}>{country.continent}</Link>
                                                </td>

                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <PopoverMap latitude={country.latitude} longitude={country.longitude} zoom={country.zoom} />
                                                </td>

                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                        {country.zoom}
                                                    </span>
                                                </td>

                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                        {country.translations}
                                                    </span>
                                                </td>

                                                <td className="px-6 py-4 whitespace-nowrap text-right">
                                                    <Link to={`/countries/${country.geonames_id}`} className="underline cursor-pointer text-blue-800 hover:text-blue-900">View</Link>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Countries;