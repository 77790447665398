import { PlusIcon } from "@heroicons/react/24/outline";
import { useContext, useRef, useState } from "react";
import config from "../../config";
import { getUserToken } from "../../contexts/AuthContext";
import { NotificationContext } from "../../contexts/NotificationContext";
import { LocationContext } from "../../pages/LocationWrapper";
import PopoverText from "../PopoverText";

function LocationAlternateNew() {
    const [location, setLocation] = useContext(LocationContext);
    const { addNotification } = useContext(NotificationContext);
    const [name, setName] = useState('');
    const [languageCode, setLanguageCode] = useState('');
    const [preferred, setPreferred] = useState(false);
    const [short, setShort] = useState(false);
    const [colloquial, setColloquial] = useState(false);
    const [historic, setHistoric] = useState(false);
    const nameRef = useRef(null);

    function addAlternate(event) {
        event.preventDefault();
        addLocationAlternate();
    };

    async function addLocationAlternate() {
        const token = await getUserToken();
        const res = await fetch(`${config.backend_url_v2}/v2/Location/AlternateName/${location.geoname_id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                name,
                language_code: languageCode,
                preferred,
                short,
                colloquial,
                historic
            })
        });

        const result = await res.json();

        if (res.status === 200) {
            const newList = [...location.alternates, {
                name,
                language_code: languageCode,
                id: result,
                custom: true,
                preferred,
                short,
                colloquial,
                historic,
                show: true
            }];

            let copy = {...location};
            copy.alternates = newList;

            setLocation(copy);
            setName('');
            setLanguageCode('');
            addNotification('Alternate name was added successfully', 'SUCCESS');

            // Put focus on the first input so you can keep typing
            if (nameRef.current) {
                nameRef.current.focus();
            }
        } else {
            addNotification(result.message, 'ERROR');
        }
    }

    return (
        <div className="flex flex-col border-t max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-6">
            <div className="mt-1 flex rounded-md">
                <form onSubmit={addAlternate} className="flex items-stretch flex-grow focus-within:z-10">
                    <input
                        ref={nameRef}
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        type="text"
                        name="name"
                        id="name"
                        className="focus:ring-blue-500 focus:border-blue-500 block w-1/6 rounded-none rounded-md sm:text-sm border-gray-300"
                        placeholder="The Big Apple or Utreg" />

                    <input
                        value={languageCode}
                        onChange={(e) => setLanguageCode(e.target.value)}
                        type="text"
                        name="language-code"
                        id="language-code"
                        className="ml-4 focus:ring-blue-500 focus:border-blue-500 block w-1/12 rounded-none rounded-md sm:text-sm border-gray-300"
                        placeholder="nl or en_US" />

                    <div className="flex items-center ml-4">
                        <input
                            id="preferred"
                            checked={preferred}
                            onChange={() => setPreferred(!preferred)}
                            name="preferred"
                            type="checkbox"
                            className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                        />

                        <label htmlFor="preferred" className="ml-2 font-small text-gray-500">
                            Preferred
                        </label>
                    </div>

                    <div className="flex items-center ml-4">
                        <input
                            id="short"
                            checked={short}
                            onChange={() => setShort(!short)}
                            name="short"
                            type="checkbox"
                            className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                        />

                        <label htmlFor="short" className="ml-2 font-small text-gray-500">
                            Short
                        </label>
                    </div>

                    <div className="flex items-center ml-4">
                        <input
                            id="colloquial"
                            checked={colloquial}
                            onChange={() => setColloquial(!colloquial)}
                            name="colloquial"
                            type="checkbox"
                            className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                        />

                        <label htmlFor="colloquial" className="ml-2 font-small text-gray-500">
                            Colloquial
                        </label>

                        <PopoverText text="Name the location is also known as. For example Big Apple for New York"/>
                    </div>

                    <div className="flex items-center ml-4">
                        <input
                            id="historic"
                            checked={historic}
                            onChange={() => setHistoric(!historic)}
                            name="historic"
                            type="checkbox"
                            className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                        />

                        <label htmlFor="historic" className="ml-2 font-small text-gray-500">
                            Historic
                        </label>

                        <PopoverText text="Name of the location that was used in the past but is not used anymore. For example Trajectum for Utrecht or Peking for Beijing"/>
                    </div>

                    {(name && languageCode) && <button
                        className="ml-4 inline-flex items-center px-4 py-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600">
                        <span>Add</span>
                        <PlusIcon className="ml-1 w-5 h-5" />
                    </button>}

                    {(!name || !languageCode) && <button
                        disabled
                        className="ml-4 inline-flex items-center px-4 py-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-300">
                        <span>Add</span>
                        <PlusIcon className="ml-1 w-5 h-5" />
                    </button>}
                </form>
            </div>
        </div>
    )
}

export default LocationAlternateNew;