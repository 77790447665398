import { Fragment, useContext, useEffect, useState } from "react";
import { AuthContext, getUserToken } from "../contexts/AuthContext";
import Header from "../components/Header";
import config from "../config";
import { CircleMarker, MapContainer, TileLayer } from "react-leaflet";
import { Link, useNavigate } from "react-router-dom";
import { CheckIcon } from "@heroicons/react/24/outline";
import { NotificationContext } from "../contexts/NotificationContext";
import { LeafletHandler } from "../util/Map";
import Error from "../components/Error";
import { wrapFetch } from "../util/Hmac";

function RegionNew() {
    const [error, setError] = useState(false);
    const [continents, setContinents] = useState([]);
    const authValue = useContext(AuthContext);
    const { addNotification } = useContext(NotificationContext);
    const [isLoaded, setIsLoaded] = useState(false);
    const [center, setCenter] = useState({lat: null, lon: null});
    const [zoom, setZoom] = useState(5);
    const [name, setName] = useState('');
    const [continent, setContinent] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        document.title = `GeoCMS\u00ae | New region`;
    }, []);

    useEffect(() => {
        async function getData() {
            try {
                const result = await wrapFetch(`${config.backend_url_v3}/v3/cms/continent`);

                setContinents(result);
                setContinent(result[0].id);
            } catch (error) {
                setError(error);
            }

            setIsLoaded(true);
        }

        if (authValue && authValue.user) {
            getData();
        }
    }, [authValue]);

    async function postData() {
        const token = await getUserToken();
        const data = {
            name_default: name,
            latitude: Number(center.lat.toFixed(6)),
            longitude: Number(center.lng.toFixed(6)),
            zoom: Number(zoom),
            continent_id: Number(continent)
        }

        const res = await fetch(`${config.backend_url_v2}/v3/cms/region`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(data)
        });

        if (res.status === 200) {
            addNotification('Region was saved successfully', 'SUCCESS');
            navigate('/regions');
        } else {
            addNotification('Something went wrong :(', 'ERROR');
        }
    }

    function submitForm(event) {
        event.preventDefault();
        postData();
    };

    if (error) {
        return (
            <Fragment>
                <Header heading="Regions" />

                <Error error={error} />
            </Fragment>
        )
    } else if (!isLoaded) {
        return (
            <Fragment>
                <Header heading="Add a new region" />

                <div className="flex flex-col max-w-5xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                    <div className="bg-gray-200 w-full animate-pulse h-16 rounded-lg mb-4"></div>
                    <div className="bg-gray-200 w-full animate-pulse h-16 rounded-lg"></div>
                </div>
            </Fragment>
        )
    } else if (continents) {
        return (
            <Fragment>
                <Header heading="Add a new region" />

                <div className="flex flex-col max-w-5xl mx-auto pb-6 px-4 sm:px-6 lg:px-8">
                    <form onSubmit={submitForm}>
                        <div className="mt-6 grid grid-cols-12 gap-y-6 gap-x-4">
                            <div className="sm:col-span-8">
                                <label htmlFor="username" className="block text-sm font-medium text-gray-700">
                                    Region name (in English)
                                </label>

                                <div className="mt-1 flex rounded-md shadow-sm">
                                    <input
                                        value={name}
                                        onInput={event => setName(event.target.value)}
                                        type="text"
                                        name="region-name"
                                        id="region-name"
                                        placeholder="De Veluwe or Costa del Sol"
                                        className="flex-1 focus:ring-blue-500 focus:border-blue-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-4">
                                <label htmlFor="continent" className="block text-sm font-medium text-gray-700">
                                    Continent
                                </label>

                                <div className="mt-1 flex rounded-md shadow-sm">
                                    <select
                                        onChange={(event) => setContinent(event.target.value)}
                                        value={continent}
                                        id="continent"
                                        name="continent"
                                        className="max-w-lg block focus:ring-blue-500 focus:border-blue-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                                    >

                                        {continents.map((continent) => (
                                            <option key={continent.id} value={continent.id}>{continent.name_default}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="flex items-center mt-4 m1-2">
                            <h3 className="text-sm leading-6 font-medium text-gray-900">Click on the map to set region center and zoom level</h3>
                        </div>

                        <div className="bg-white shadow overflow-hidden sm:rounded-lg h-half">
                            <MapContainer center={[50, 4]} zoom={5}>
                                <TileLayer
                                    attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors - &copy; <a href="https://maptiler.com/copyright">MapTiler</a>'
                                    url="https://maps.meteoplaza.com/styles/topo/{z}/{x}/{y}.png"
                                />

                                <LeafletHandler newCenter={(latlng) => setCenter(latlng)} newZoom={(zoom) => setZoom(zoom)} />

                                {center && <CircleMarker
                                    center={center}
                                    pathOptions={{ color: 'black' }}
                                    radius={10}>
                                </CircleMarker>}
                            </MapContainer>
                        </div>

                        {center && center.lat && center.lon && <div className="flex mt-6">
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                Lat {center.lat.toFixed(6)}, Long {center.lng.toFixed(6)}
                            </span>

                            <span className="ml-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                Zoom {zoom}
                            </span>
                        </div>}

                        <div className="pt-5">
                            <div className="flex justify-end">
                                <Link
                                    to='/regions'
                                    className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                                    Cancel
                                </Link>

                                {(name && continent && center && zoom) && <button
                                    type="submit"
                                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                                    <CheckIcon className="h-5 w-5 mr-1" />
                                    Save
                                </button>}

                                {(!name || !continent || !center || !zoom) && <button
                                    disabled
                                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-200">
                                    <CheckIcon className="h-5 w-5 mr-1" />
                                    Save
                                </button>}
                            </div>
                        </div>
                    </form>
                </div>
            </Fragment>
        )
    }
}

export default RegionNew;