import { CheckCircleIcon, PlusIcon, ArrowPathIcon, MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { useContext, useEffect, useState } from "react";
import config from "../../config";
import { POIContext } from "../../pages/POIWrapper";
import { formatPossibleEmpty } from "../../util/Format";
import { wrapFetch } from "../../util/Hmac";

function POISearch() {
    const [poi, setPOI] = useContext(POIContext);
    const [query, setQuery] = useState('');
    const [input, setInput] = useState('');
    const [loading, setLoading] = useState(false);
    const [searchResults, setSearchResults] = useState([]);

    useEffect(() => {
        async function getData() {
            try {
                setLoading(true);

                const result = await wrapFetch(`${config.backend_url_v3}/v3/weeronline/search?lang=en&search=${query}`);

                const locations = result.filter((item) => item.type === 'location');
                setSearchResults(locations);
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        }

        if (query && query.length > 0) {
            getData();
        }
    }, [query]);

    function searchInput(event) {
        event.preventDefault();
        setQuery(input);
    };

    function setPOILocation(location) {
        setQuery('');
        setInput('');
        setSearchResults([]);

        let copy = { ...poi };
        copy.geoname_id = location.geoname_id;
        copy.location_name = location.name;
        copy.location_latitude = location.latitude;
        copy.location_longitude = location.longitude;
        copy.country_name = location.country_name;
        copy.country_id = location.country_id;

        setPOI(copy);
    }

    return (
        <div className="col-span-2">
            <div className="flex mt-2 items-center">
                <span className="block text-gray-900">
                    {poi.location_name}
                </span>

                <CheckCircleIcon className="ml-1 w-5 h-5 text-green-700" />
            </div>

            <form onSubmit={searchInput} className="mt-2 flex relative items-stretch flex-grow focus-within:z-10">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>

                <input
                    value={input}
                    onInput={event => setInput(event.target.value)}
                    type="search"
                    name="search"
                    id="search"
                    className="focus:ring-blue-500 focus:border-blue-500 block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300"
                    placeholder="Search for location name"
                />

                <button
                    type="submit"
                    disabled={loading}
                    className="-ml-px inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500">

                    {loading && <ArrowPathIcon className="animate-spin mr-1 h-4 w-4" />}
                    <span>Search</span>
                </button>
            </form>

            {searchResults.length > 0 && <div>
                <div className="flex items-center space-x-3 mb-2 mt-4">
                    <h2>Search results</h2>
                </div>

                <div className="bg-white border border-gray-300 overflow-hidden rounded-md">
                    <ul className="divide-y divide-gray-300">
                        {searchResults.map((item) => (
                            <li key={item.geoname_id} className="p-4">
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center">
                                        <span>{item.name}</span>

                                        <p className="ml-4 text-sm font-medium text-gray-500 truncate">{item.country_name} - {formatPossibleEmpty(item.admin_name)}</p>
                                    </div>

                                    {item.location_id && <button
                                        disabled={loading}
                                        onClick={() => setPOILocation(item)}
                                        type="button"
                                        className="inline-flex items-center px-2 py-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                                        Use location
                                        <PlusIcon className="ml-1 h-5 w-5"></PlusIcon>
                                    </button>}
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            }

            {searchResults.length === 0 && query.length > 0 && <div>
                <p>Nothing found for "{query}"</p>
            </div>}
        </div>
    )
}

export default POISearch;