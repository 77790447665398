import { initializeApp } from "@firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider, onIdTokenChanged } from "firebase/auth";
import { createContext, useContext, useEffect, useState } from "react";

const firebaseConfig = {
    apiKey: "AIzaSyB5ANjJEHTlV1QQcJ1moCQgSd0X4_2sLWQ",
    authDomain: "geo-cms-ip.firebaseapp.com",
    projectId: "geo-cms-ip",
    storageBucket: "geo-cms-ip.appspot.com",
    messagingSenderId: "746743970213",
    appId: "1:746743970213:web:bbdab21df1b2907d15904c"
};

initializeApp(firebaseConfig);
const provider = new GoogleAuthProvider();
const auth = getAuth();

function login() {
    signInWithPopup(auth, provider)
        .catch((error) => {
            console.log(error);
        });
}

function logout() {
    auth.signOut();
};

const AuthContext = createContext();

const AuthContextProvider = (props) => {
    const [user, setUser] = useState();
    const [error, setError] = useState();

    useEffect(() => {
        const unsubscribe = onIdTokenChanged(getAuth(), setUser, setError);

        return () => {
            unsubscribe();
        }
    }, []);

    return <AuthContext.Provider value={{ user, error }} {...props} />
}

function useAuthState () {
    const auth = useContext(AuthContext);

    return {
        ...auth,
        isAuthenticated: auth.user !== null
    }
}

async function getUserToken() {
    const currentUser = auth.currentUser;

    if (currentUser) {
        const token = await currentUser.getIdToken();
        return token;
    } else {
        return '';
    }
}

export {
    auth,
    login,
    logout,
    useAuthState,
    getUserToken,
    AuthContext,
    AuthContextProvider
}