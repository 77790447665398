import { Fragment, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import queryString from 'query-string';
import { formatPossibleEmpty, roundTo } from "../../util/Format";
import config from "../../config";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { useLocation } from "react-router-dom";
import { wrapFetch } from "../../util/Hmac";

function SearchMeilisearch() {
    const { search } = useLocation();
    const [input, setInput] = useState('');
    const [query, setQuery] = useState('');
    const [lang, setLang] = useState('nl');
    const [brand, setBrand] = useState('weeronline');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const navigate = useNavigate();
    const authValue = useContext(AuthContext);

    function searchInput(event) {
        event.preventDefault();
        changeSearch(input, lang, brand);
    };

    function changeLang(event) {
        event.preventDefault();
        setLang(event.target.value);
        changeSearch(input, event.target.value, brand);
    }

    function changeBrand(event) {
        event.preventDefault();
        setBrand(event.target.value);
        changeSearch(input, lang, event.target.value);
    }

    function changeSearch(query, language, brandKey) {
        let params = queryString.parse(search);

        if (!params.l) {
            params.l = 'nl';
        }

        if (params.q !== query || params.b !== brandKey || params.l !== language) {
            params.q = query;

            if (language) {
                params.l = language;
            }

            if (brandKey) {
                params.b = brandKey;
            }

            navigate(`/search?${queryString.stringify(params)}`);
        }
    }

    function showLocation(location) {
        return (
            <li key={location.tracking_id} className="flex items-center px-6 py-4">
                <div>
                    <div className="flex items-center">
                        <div className="flex">
                            <Link to={`/locations/${location.geoname_id}`} className="underline cursor-pointer text-blue-800 hover:text-blue-900">{location.name}</Link>

                            <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                                {location.type}
                            </span>
                        </div>
                    </div>

                    {location.country_name && <p className="text-sm font-medium text-gray-500 truncate">{location.country_name} - {formatPossibleEmpty(location.admin_name)}</p>}
                    {location.wintersport && <p className="mt-2 inline-flex rounded-full bg-green-100 px-2.5 py-0.5 text-xs font-medium text-green-800">wintersport</p>}
                </div>

                {(location.score !== null && location.score !== undefined) && <div className="flex items-center ml-auto">
                    <span className="mr-2 text-sm text-gray-400">Search score</span>

                    <span className="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">
                        {roundTo(location.score, 2)}
                    </span>
                </div>}
            </li>
        )
    }

    function showRegion(region) {
        return (
            <li key={region.tracking_id} className="px-6 py-4">
                <div className="flex items-center">
                    <div className="flex">
                        <Link to={`/regions/${region.region_id}`} className="underline cursor-pointer text-blue-800 hover:text-blue-900">{region.name}</Link>

                        <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                            {region.type}
                        </span>
                    </div>
                </div>

                <p className="text-sm font-medium text-gray-500 truncate">{region.continent_name}</p>
            </li>
        )
    }

    function showPOI(poi) {
        return (
            <li key={poi.tracking_id} className="px-6 py-4">
                <div className="flex items-center">
                    <div className="flex">
                        <Link to={`/pois/${poi.poi_id}`} className="underline cursor-pointer text-blue-800 hover:text-blue-900">{poi.name}</Link>

                        <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                            {poi.type}
                        </span>
                    </div>
                </div>

                {poi.country_name && <p className="text-sm font-medium text-gray-500 truncate">{poi.country_name} - {formatPossibleEmpty(poi.admin_name)}</p>}
            </li>
        )
    }

    function showEvent(event) {
        return (
            <li key={event.tracking_id} className="px-6 py-4">
                <div className="flex items-center">
                    <div className="flex">
                        <Link to={`/events/${event.event_id}`} className="underline cursor-pointer text-blue-800 hover:text-blue-900">{event.name}</Link>

                        <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                            {event.type}
                        </span>
                    </div>
                </div>

                {event.country_name && <p className="text-sm font-medium text-gray-500 truncate">{event.country_name} - {formatPossibleEmpty(event.admin_name)}</p>}
            </li>
        )
    }

    function showCountry(country) {
        return (
            <li key={country.tracking_id} className="px-6 py-4">
                <div className="flex items-center">
                    <div className="flex">
                        <Link to={`/countries/${country.geoname_id}`} className="underline cursor-pointer text-blue-800 hover:text-blue-900">{country.name}</Link>

                        <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                            {country.type}
                        </span>
                    </div>
                </div>

                <p className="text-sm font-medium text-gray-500 truncate">{country.continent_name}</p>
            </li>
        )
    }

    function showResult(item) {
        if (item.type === 'location') {
            return showLocation(item);
        } else if (item.type === 'region') {
            return showRegion(item);
        } else if (item.type === 'poi') {
            return showPOI(item);
        } else if (item.type === 'country') {
            return showCountry(item);
        } else if (item.type === 'event') {
            return showEvent(item);
        }
    }

    useEffect(() => {
        const params = queryString.parse(search);

        if (params.q && params.q.length > 0) {
            const queryString = decodeURI(params.q);
            const langString = decodeURI(params.l);

            setInput(queryString);
            setLang(langString);
            setQuery(queryString);
        }
    }, [search]);

    useEffect(() => {
        async function getData() {
            try {
                setLoading(true);

                if (!lang) {
                    setLang('nl');
                }

                const escapedQuery = encodeURIComponent(query);
                const result = await wrapFetch(`${config.backend_url_v3}/v3/${brand}/search?lang=${lang}&search=${escapedQuery}`);

                setSearchResults(result);
            } catch (error) {
                setError(error);
                setLoading(false);
            } finally {
                setLoading(false);
            }
        }

        if (query && query.length > 0 && authValue && authValue.user) {
            getData();
        }
    }, [query, lang, brand, navigate, authValue]);

    function showList() {
        if (loading) {
            return (
                <Fragment>
                    <div className="bg-gray-200 w-full animate-pulse h-16 rounded-lg pt-4 mb-4"></div>
                    <div className="bg-gray-200 w-full animate-pulse h-16 rounded-lg"></div>
                </Fragment>
            )
        } else if (searchResults && searchResults && searchResults.length > 0 && query.length > 0) {
            return (
                <Fragment>
                    <div className="flex items-center mb-2">
                        <h2>Search results</h2>

                        <span className="ml-4 inline-block px-3 py-1 text-green-800 text-xs font-medium bg-green-100 rounded-full">
                            hits {searchResults.length}
                        </span>
                    </div>

                    <div className="bg-white border border-gray-300 overflow-hidden rounded-md">
                        <ul className="divide-y divide-gray-300">
                            {searchResults.map((item) => (
                                showResult(item)
                            ))}
                        </ul>
                    </div>
                </Fragment>
            )
        } else if (error || (searchResults && searchResults.length === 0 && query.length > 0)) {
            return (
                <h2>No results found for "{query}"</h2>
            )
        }
    }

    return (
        <div className="Search">
            <div className="flex flex-col max-w-7xl mx-auto pt-6 px-8">
                <div className="mt-1 flex rounded-md">
                    <select
                        id="lang"
                        name="lang"
                        value={lang}
                        onChange={(e) => changeLang(e)}
                        className="mr-1 block rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                    >
                        <option value="nl">Dutch</option>
                        <option value="en">English</option>
                        <option value="de">German</option>
                        <option value="fr">French</option>
                        <option value="fy">Frisian</option>
                        <option value="it">Italian</option>
                        <option value="tr">Turkish</option>
                        <option value="el">Greek</option>
                        <option value="es">Spanish</option>
                        <option value="si">Sinhalese</option>
                        <option value="pt">Portugese</option>
                        <option value="pl">Polish</option>
                        <option value="hu">Hungarian</option>
                        <option value="hr">Croatian</option>
                    </select>

                    <select
                        id="brand"
                        name="brand"
                        value={brand}
                        onChange={(e) => changeBrand(e)}
                        className="mr-1 block rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm">
                        <option value="weeronline">Weeronline</option>
                        <option value="weerplaza">Weerplaza</option>
                    </select>

                    <form onSubmit={searchInput} className="relative flex items-stretch flex-grow focus-within:z-10">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </div>

                        <input
                            value={input}
                            onInput={event => setInput(event.target.value)}
                            type="search"
                            name="search"
                            id="search"
                            className="focus:ring-blue-500 focus:border-blue-500 block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300"
                            placeholder="Search Meilisearch"
                        />

                        <button
                            type="submit"
                            className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500">
                            <span>Search</span>
                        </button>
                    </form>
                </div>
            </div>

            <div className="flex flex-col max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                {showList()}
            </div>
        </div>
    )
}

export default SearchMeilisearch;