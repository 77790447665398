import { createContext, useState } from "react";
import CountryDetail from "../components/country/CountryDetail";

export const CountryContext = createContext({
    country: {},
    setCountry: () => {}
});

function CountryWrapper() {
    const [country, setCountry] = useState({});

    return (
        <CountryContext.Provider value={[country, setCountry]}>
            <CountryDetail></CountryDetail>
        </CountryContext.Provider>
    )
}

export default CountryWrapper;