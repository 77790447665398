import { useContext, useEffect, useState } from "react";
import Header from "../Header";
import { useParams } from "react-router-dom";
import config from "../../config";
import { AuthContext } from "../../contexts/AuthContext";
import Error from "../Error";
import ContinentTranslations from "./ContinentTranslations";
import { ContinentContext } from "../../pages/ContinentWrapper";
import ContinentMap from "./ContinentMap";
import { generateId } from "../../util/Random";
import { wrapFetch } from "../../util/Hmac";

function ContinentDetail() {
    const {id} = useParams();
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [continent, setContinent] = useContext(ContinentContext);
    const authValue = useContext(AuthContext);

    useEffect(() => {
        async function getData() {
            try {
                const result = await wrapFetch(`${config.backend_url_v3}/v3/cms/continent/${id}?cb=${generateId(8)}`);

                document.title = `GeoCMS\u00ae | ${result.name_default}`;

                // Sort translations by language code
                result.translations.sort((a, b) => {
                    if (a.language_code < b.language_code) {
                        return -1;
                    } else if (a.language_code > b.language_code) {
                        return 1;
                    }

                    return 0;
                });

                setContinent(result);
                setIsLoaded(true);
            } catch (error) {
                setError(error);
                setIsLoaded(true);
            }
        }

        if (authValue && authValue.user) {
            getData();
        }
    }, [id, setContinent, authValue]);

    if (error) {
        return (
            <div className="Continent">
                <Header heading="Continent"/>

                <Error error={error} />
            </div>
        )
    } else if (!isLoaded) {
        return (
            <div className="Continent">
                <Header heading="Continent" loading={true} />

                <div className="flex flex-col max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                    <div className="bg-gray-200 w-full animate-pulse h-64 rounded-lg mb-4"></div>
                </div>
            </div>
        )
    } else if (continent) {
        return (
            <div className="Continent">
                <Header heading={continent.name_default}/>

                <div className="flex flex-col max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                    <ContinentTranslations />

                    <ContinentMap />
                </div>
            </div>
        )
    }
}

export default ContinentDetail;