import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import Header from "../Header";
import config from "../../config";
import Error from "../Error";
import { generateId } from "../../util/Random";
import { wrapFetch } from "../../util/Hmac";
import MapMap from "./MapMap";
import { MapContext } from "../../pages/MapWrapper";

function MapDetail() {
    const { id } = useParams();
    const [map, setMap] = useContext(MapContext);
    const authValue = useContext(AuthContext);
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        async function getData() {
            try {
                const result = await wrapFetch(`${config.backend_url_v3}/v3/cms/mapdata/${id}?cb=${generateId(8)}`);

                setMap(result);
                document.title = `GeoCMS\u00ae | ${result.map_name}`;
            } catch (error) {
                setError(error);
            } finally {
                setIsLoaded(true);
            }
        }

        if (authValue && authValue.user) {
            getData();
        }
    }, [id, setMap, authValue]);

    if (error) {
        return (
            <div className="Map">
                <Header heading="Map" />

                <Error error={error} />
            </div>
        )
    } else if (!isLoaded) {
        return (
            <div className="Map">
                <Header heading="Map" loading={true} />

                <div className="flex flex-col max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                    <div className="bg-gray-200 w-full animate-pulse h-64 rounded-lg mb-4"></div>
                </div>
            </div>
        )
    } else if (map) {
        return (
            <div className="Map">
                <Header heading={map.map_name} ></Header>

                <div className="flex flex-col max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                    <MapMap />
                </div>
            </div>
        )
    }
}

export default MapDetail;