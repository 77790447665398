import { useContext, useState, useEffect } from "react";
import { POIContext } from "../../pages/POIWrapper";
import EditRow from "../EditRow";
import { format } from 'date-fns';
import { DateTimePicker } from 'react-rainbow-components';
import config from "../../config";
import { getUserToken } from "../../contexts/AuthContext";
import { NotificationContext } from "../../contexts/NotificationContext";

function POIEvent() {
    const [poi] = useContext(POIContext);
    const [editing, setEditing] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const { addNotification } = useContext(NotificationContext);
    const [endDate, setEndDate] = useState(null);

    useEffect(() => {
        try {
            if (poi && poi.start_date && poi.end_date) {
                const sd = new Date(poi.start_date);
                setStartDate(sd);

                const ed = new Date(poi.end_date);
                setEndDate(ed);
            } else {
                setStartDate(null);
                setEndDate(null);
            }
        } catch (error) {
            console.log(error);
        }
    }, [poi]);

    async function updateEvent() {
        const token = await getUserToken();
        const res = await fetch(`${config.backend_url_v2}/v2/POI/EventPOI/${poi.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                is_event: true,
                start_date: localISOTime(startDate),
                end_date: localISOTime(endDate)
            })
        });

        if (res.status === 200) {
            setEditing(false);
            addNotification('Event data was updated successfully', 'SUCCESS');
        } else {
            const result = await res.json();
            addNotification(result.message, 'ERROR');
        }
    }

    function localISOTime (date) {
        const x = date.getTimezoneOffset() * 60000;
        const localISOTime = (new Date(date - x)).toISOString();
        return localISOTime;
    }

    return (
        <div className="bg-white shadow overflow-hidden sm:rounded-lg mt-6">
            <div className="flex justify-between px-4 py-5 sm:px-6">
                <div className="flex items-center">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">POI Event</h3>
                </div>

                <EditRow
                    cancel={true}
                    editState={editing}
                    save={() => updateEvent()}
                    editing={(state) => setEditing(state)}></EditRow>
            </div>

            <div className="border-t border-gray-200">
                <dl>
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                            Start date
                        </dt>

                        {(!editing && startDate) && <dd className="mt-1 sm:mt-0 sm:col-span-2">
                            <span className="text-sm text-gray-900">{format(startDate, "dd-MM-yyyy HH:mm")}</span>
                        </dd>}

                        {(!editing && !startDate) && <dd className="mt-1 sm:mt-0 sm:col-span-2">
                            <span className="text-sm text-gray-600">Fill me in &#x1F31E;</span>
                        </dd>}

                        {editing && <DateTimePicker
                            value={startDate}
                            locale="nl-NL"
                            formatStyle="large"
                            onChange={value => setStartDate(value)}
                            hideLabel
                            hour24
                        />}
                    </div>
                </dl>

                <dl>
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                            End date
                        </dt>

                        {(!editing && endDate) && <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            <span className="text-sm text-gray-900">{format(endDate, "dd-MM-yyyy HH:mm")}</span>
                        </dd>}

                        {(!editing && !endDate) && <dd className="mt-1 sm:mt-0 sm:col-span-2">
                            <span className="text-sm text-gray-600">Fill me in &#x1F31E;</span>
                        </dd>}

                        {editing && <DateTimePicker
                            value={endDate}
                            locale="nl-NL"
                            formatStyle="large"
                            onChange={value => setEndDate(value)}
                            hideLabel
                            hour24
                        />}
                    </div>
                </dl>
            </div>
        </div>
    )
}

export default POIEvent;