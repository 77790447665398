import GitInfo from 'react-git-info/macro';

export function setMetaVersion() {
    try {
        const element = document.querySelector("meta[name='ui-version'");

        const gitInfo = GitInfo();

        if (element) {
            element.setAttribute('content', gitInfo.commit.shortHash.toUpperCase());
        }
    } catch (error) {
        console.log('Could not set UI version', error);
    }
}