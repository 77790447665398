function Error(props) {
    return (
        <section className="flex flex-col w-full items-center justify-center p-16">
            <h2 className="text-xl font-medium text-gray-900">Something made Mr. Cool Cloud cry!</h2>

            <img src="/mr-cool-cloud.svg" width="250" height="250" alt="Mr. Cool Cloud" />

            {props.error && <pre className="p-4 bg-gray-100 text-gray-600 rounded-md">
                {JSON.stringify(props.error, undefined, 4)}
            </pre>}
        </section>
    )
}

export default Error;