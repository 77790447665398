import { createContext, useState } from "react";
import EventDetail from "../components/event/EventDetail";

export const EventContext = createContext({
    event: {},
    setEvent: () => {}
});

function EventWrapper() {
    const [event, setEvent] = useState({});

    return (
        <EventContext.Provider value={[event, setEvent]}>
            <EventDetail />
        </EventContext.Provider>
    )
}

export default EventWrapper;