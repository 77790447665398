import { Fragment } from "react";
import { Link } from "react-router-dom";
import { formatPossibleEmpty } from "../../util/Format";

function SearchResultId(props) {
    const result = props.results;

    if (result.location_name) {
        return (
            <Fragment>
                <h2>ID detected</h2>

                <div className="bg-white overflow-hidden rounded-md">
                    <div className="bg-white overflow-hidden shadow rounded-lg border border-gray-300 mb-4">
                        <div className="px-4 py-5 sm:p-6">
                            <h2 className="text-gray-400">weeronline</h2>
                            <h3 className="text-lg leading-6 font-large text-gray-900">{result.location_name}</h3>

                            <span className="text-gray-600">{formatPossibleEmpty(result.adm1_full)} - {formatPossibleEmpty(result.adm2_full)}</span>
                        </div>

                        <div className="bg-gray-50 px-4 py-4 sm:px-6">
                            <Link
                                to={`/locations/${result.geoname_id}`}
                                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                            >
                                View location
                            </Link>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    } else {
        return (
            <h2>Not found</h2>
        )
    }
}

export default SearchResultId;