import { Dialog } from '@headlessui/react';
import { useRef, useContext } from 'react';
import { ModalContext } from '../contexts/ModalContext';

/**
 * Opens a modal.
 * User pressing the actionButton will execute the actionFunction.
 * @param title
 * @param body
 * @param cancelText
 * @param actionText
 * @param actionFunction
 */
function Modal(props) {
    const { closeModal } = useContext(ModalContext);
    const cancelButtonRef = useRef(null);

    function action() {
        if (props.actionFunction) {
            props.actionFunction();
        }

        closeModal();
    }

    return (
        <Dialog as="div" className="fixed flex items-center justify-center z-10 inset-0 overflow-y-auto" open={true} initialFocus={cancelButtonRef} onClose={() => { closeModal() }}>
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all max-w-md">
                <div className="flex flex-col">
                    {props.title && <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        {props.title}
                    </Dialog.Title>}

                    <div className="mt-2">
                        {props.body && <Dialog.Description as="p" className="text-sm text-gray-500">
                            {props.body}
                        </Dialog.Description>}
                    </div>

                    <div className="flex mt-4 flex-row-reverse">
                        <button className="ml-2 inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-sm font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500" onClick={() => action()}>{props.actionText}</button>
                        <button ref={cancelButtonRef} className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-400 text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500" onClick={() => closeModal()}>{props.cancelText}</button>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default Modal;