import { useContext } from "react";
import { CircleMarker, MapContainer, TileLayer } from "react-leaflet";
import { useEffect, useState } from "react";
import { roundTo } from "../../util/Format";
import { getUserToken } from "../../contexts/AuthContext";
import { NotificationContext } from "../../contexts/NotificationContext";
import config from "../../config";
import EditRow from "../EditRow";
import { LeafletHandler } from "../../util/Map";
import { MapContext } from "../../pages/MapWrapper";

function MapMap() {
    const [map, setMap] = useContext(MapContext);
    const [center, setCenter] = useState({lat: null, lng: null});
    const [zoom, setZoom] = useState(null);
    const { addNotification } = useContext(NotificationContext);
    const [editing, setEditing] = useState(false);
    const [mapSize] = useState({ width: 538, height: 538 });

    useEffect(() => {
        if (map && map.center_latitude && map.center_longitude) {
            setCenter({ lat: map.center_latitude, lng: map.center_longitude });
            setZoom(map.map_zoom);
        }
    }, [map]);

    async function updateMap() {
        const token = await getUserToken();
        const res = await fetch(`${config.backend_url_v3}/v3/cms/mapdata/${map.map_id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                center_latitude: Number(roundTo(center.lat, 6)),
                center_longitude: Number(roundTo(center.lng, 6)),
                map_zoom: Number(roundTo(zoom, 1))
            })
        });

        if (res.status === 200) {
            let copy = { ...map };
            copy.latitude = Number(roundTo(center.lat, 6));
            copy.longitude = Number(roundTo(center.lng, 6));
            copy.zoom = zoom;

            setMap(copy);
            setEditing(false);
            addNotification('Map was editted successfully', 'SUCCESS');
        } else {
            const result = await res.json();
            addNotification(result.message, 'ERROR');
        }
    }

    function changeCenter(latlng) {
        if (editing) {
            setCenter(latlng);
        }
    }

    if (!map || !map.center_latitude || !map.center_longitude) {
        return null;
    }

    if (map) {
        return (
            <section className="bg-white shadow overflow-hidden rounded-lg flex full-w flex-col my-6">
                <div className="flex items-center justify-between px-4 py-5 sm:px-6">
                    <div>
                        <div className="flex items-center">
                            <h2 className="text-2xl font-bold text-gray-900">
                                Map center and zoom
                            </h2>

                            <div className="flex ml-4">
                                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                    Lat {roundTo(center.lat, 6)}, Long {roundTo(center.lng, 6)}
                                </span>

                                {!editing && <span className="ml-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                    Zoom {map.map_zoom}
                                </span>}

                                {editing && <span className="ml-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                    Zoom {zoom}
                                </span>}
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-center">
                        <EditRow
                            cancel={true}
                            buttonText="Save"
                            editState={editing}
                            save={() => { updateMap() }}
                            editing={(state) => { setEditing(state) }} />
                    </div>
                </div>

                <div className="block" style={{ height: `${mapSize.height}px`, width: `${mapSize.width}px` }}>
                    <MapContainer center={[map.center_latitude, map.center_longitude]} zoom={map.map_zoom} zoomDelta={0.1} zoomSnap={0.1}>
                        <TileLayer
                            attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors - &copy; <a href="https://maptiler.com/copyright">MapTiler</a>'
                            url="https://maps.meteoplaza.com/styles/imweather-overlay-black/{z}/{x}/{y}.png" />

                        {center && <CircleMarker
                            center={center}
                            pathOptions={{ color: 'black' }}
                            radius={10}>
                        </CircleMarker>}

                        <LeafletHandler newCenter={(latlng) => changeCenter(latlng)} newZoom={(zoom) => setZoom(zoom)} />
                    </MapContainer>
                </div>
            </section>
        )
    }
}

export default MapMap;