export function detectInputType(input) {
    const latlonTest = new RegExp(/^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/);

    if (latlonTest.test(input)) {
        return 'latlon';
    } else if (!isNaN(input)) {
        return 'id';
    } else {
        return 'query';
    }
}

export function getLatLonValues(input) {
    const nospaces = input.trim().replace(' ', '');
    const splitup = nospaces.split(',');
    const lat = Number(splitup[0]);
    const lon = Number(splitup[1]);

    return [lat, lon];
}