import { useContext, useEffect, useState } from "react";
import { CountryContext } from "../../pages/CountryWrapper";
import { PlusIcon } from "@heroicons/react/24/solid";
import { wrapFetch } from "../../util/Hmac";
import config from "../../config";
import { generateId } from "../../util/Random";
import { latinize } from "../../util/Format";
import { getUserToken } from "../../contexts/AuthContext";
import { NotificationContext } from "../../contexts/NotificationContext";

function CountryMapEdit() {
    const [country, setCountry] = useContext(CountryContext);
    const [input, setInput] = useState('');
    const [maps, setMaps] = useState([]);
    const { addNotification } = useContext(NotificationContext);
    const [filteredMaps, setFilteredMaps] = useState([]);

    async function addMap(mapId, countryId) {
        const token = await getUserToken();
        const res = await fetch(`${config.backend_url_v3}/v3/cms/country/mapdata`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                country_id: countryId,
                map_id: mapId
            })
        });

        if (res.status === 200) {
            let copy = { ...country };
            const list = maps.find((map) => map.map_id === mapId);
            copy.country_maps.push({
                map_id: mapId,
                map_name: list.map_name,
            });

            setCountry(copy);
            addNotification('Map was added!', 'SUCCESS');
        }
    }

    async function deleteMap(mapId, countryId) {
        const token = await getUserToken();
        const res = await fetch(`${config.backend_url_v3}/v3/cms/country/mapdata`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                country_id: countryId,
                map_id: mapId
            })
        });

        if (res.status === 200) {
            let copy = { ...country };

            const filteredMaps = copy.country_maps.filter((map) => map.map_id !== mapId);
            copy.country_maps = filteredMaps;

            setCountry(copy);
            addNotification('Map was removed!', 'SUCCESS');
        }
    }

    useEffect(() => {
        setFilteredMaps(maps);
    }, [maps]);

    useEffect(() => {
        const newMaps = maps.filter((map) => latinize(map.map_name).toLowerCase().includes(input.toLowerCase()));
        setFilteredMaps(newMaps);
    }, [maps, input]);

    // Get the full list of lists
    useEffect(() => {
        async function getData() {
            try {
                const result = await wrapFetch(`${config.backend_url_v3}/v3/cms/mapdata?cb=${generateId(6)}`);

                setMaps(result);
            } catch (error) {
                console.log(error);
            }
        }

        getData();
    }, []);

    return (
        <div>
            {country.country_maps.map(map => (
                <div key={map.map_id} className="flex justify-between items-center bg-blue-100 mx-6 p-4 my-2 rounded">
                    <span>{map.map_name}</span>

                    <button
                        onClick={() => deleteMap(map.map_id, country.geonames_id)}
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-300">
                        Remove
                    </button>
                </div>
            ))}

            <form>
                <div className="flex flex-col border mx-6 p-4">
                    <input
                        onInput={event => setInput(event.target.value)}
                        type="search"
                        name="search"
                        id="search"
                        className="focus:ring-blue-500 focus:border-blue-500 block w-full rounded pl-4 sm:text-sm border-gray-300"
                        placeholder="Search for list"
                    />
                </div>
            </form>

            {filteredMaps && input.length >= 3 && filteredMaps.map(map => (
                <div key={map.map_id} className="flex justify-between center-items bg-blue-100 mx-6 p-4 my-2 rounded">
                    <span>{map.map_name}</span>
                    <button
                        onClick={() => addMap(map.map_id, country.geonames_id)}
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500">
                        Add
                        <PlusIcon className="ml-1 h-5 w-5" />
                    </button>
                </div>
            ))}
        </div>
    )
}

export default CountryMapEdit;