import { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { AuthContext, getUserToken } from "../../contexts/AuthContext";
import Header from "../Header";
import config from "../../config";
import { POIContext } from "../../pages/POIWrapper";
import POIEvent from "./POIEvent";
import POIMap from "./POIMap";
import Error from "../Error";
import POIDetails from "./POIDetails";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { NotificationContext } from "../../contexts/NotificationContext";
import { ModalContext } from "../../contexts/ModalContext";
import { generateId } from "../../util/Random";
import { wrapFetch } from "../../util/Hmac";

function POIDetail() {
    const navigate = useNavigate();
    const { addNotification } = useContext(NotificationContext);
    const { openModal } = useContext(ModalContext);
    const { id } = useParams();
    const [poi, setPOI] = useContext(POIContext);
    const authValue = useContext(AuthContext);
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    async function deletePOI() {
        const token = await getUserToken();
        const res = await fetch(`${config.backend_url_v2}/v2/POI/${poi.id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        if (res.status === 200) {
            navigate('/pois');
            addNotification('POI was removed successfully', 'SUCCESS');
        } else {
            const result = await res.json();
            addNotification(result.message, 'ERROR');
        }
    }

    useEffect(() => {
        async function getData() {
            try {
                const result = await wrapFetch(`${config.backend_url_v3}/v3/cms/poi/${id}?cb=${generateId(6)}`);

                document.title = `GeoCMS\u00ae | ${result.name}`;
                setPOI(result);
            } catch (error) {
                setError(error);
            } finally {
                setIsLoaded(true);
            }
        }

        if (authValue && authValue.user) {
            getData();
        }
    }, [id, setPOI, authValue]);

    if (error) {
        return (
            <div className="POI">
                <Header heading="POI" />

                <Error error={error} />
            </div>
        )
    } else if (!isLoaded) {
        return (
            <div className="POI">
                <Header heading="POI" loading={true} />

                <div className="flex flex-col max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                    <div className="bg-gray-200 w-full animate-pulse h-64 rounded-lg mb-4"></div>
                </div>
            </div>
        )
    } else if (poi) {
        return (
            <div className="POI">
                <Header heading={poi.name} >
                    <button
                        onClick={() => openModal(`You are about to remove ${poi.name}`, 'Are you sure you want to remove this place of interest? You can\'t undo this action later.', 'Cancel', 'Remove', () => deletePOI())}
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-300">
                        Remove
                        <XMarkIcon className="ml-1 h-5 w-5" />
                    </button>
                </Header>

                <div className="flex flex-col max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                    <POIDetails />

                    {(poi.is_event === true) && <POIEvent />}

                    <POIMap />
                </div>
            </div>
        )
    }
}

export default POIDetail;