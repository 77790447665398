import { Popover } from '@headlessui/react';
import { CircleMarker, MapContainer, TileLayer } from 'react-leaflet';

function PopoverMap(props) {
    if (props.latitude && props.longitude) {
        return (
            <Popover>
                <Popover.Button>
                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 hover:bg-green-200">
                        {props.latitude}, {props.longitude}
                    </span>
                </Popover.Button>

                <Popover.Panel className="absolute z-10 w-80 h-80 mt-3 rounded-lg">
                    <MapContainer center={[props.latitude, props.longitude]} zoom={(props.zoom - 1) || 8}>
                        <TileLayer
                            attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors - &copy; <a href="https://maptiler.com/copyright">MapTiler</a>'
                            url="https://maps.meteoplaza.com/styles/topo/{z}/{x}/{y}.png" />

                        <CircleMarker
                            center={[props.latitude, props.longitude]}
                            pathOptions={{ color: 'black' }}
                            radius={10}>
                        </CircleMarker>
                    </MapContainer>
                </Popover.Panel>
            </Popover>
        )
    } else {
        return (
            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                Unknown
            </span>
        )
    }
}

export default PopoverMap;