import { Fragment } from "react";
import { MapContainer, TileLayer, CircleMarker, useMapEvents } from "react-leaflet";
import { plotCitiesWithLink } from "../../util/Map";

function ClickHandler({ onMapClick }) {
    useMapEvents({
        click(e) {
            onMapClick(e.latlng);
        }
    });
    return null;
}

function SearchResultMap(props) {
    const results = props.results;

    if (!results || results.length === 0) {
        return null;
    }

    return (
        <Fragment>
            <h2 className="mb-2">Showing locations in 20km radius</h2>

            <div className="bg-white overflow-hidden rounded-md h-half">
                <MapContainer className="border-t border-gray-200" center={[props.lat, props.lon]} zoom={10}>
                    <TileLayer
                        attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors - &copy; <a href="https://maptiler.com/copyright">MapTiler</a>'
                        url="https://maps.meteoplaza.com/styles/topo/{z}/{x}/{y}.png"
                    />

                    <CircleMarker
                        center={[props.lat, props.lon]}
                        pathOptions={{color: 'black'}}
                        radius={10}>
                    </CircleMarker>

                    {plotCitiesWithLink(results)}

                    <ClickHandler onMapClick={props.onMapClick} />
                </MapContainer>
            </div>
        </Fragment>
    )
}

export default SearchResultMap;