import { Fragment, useContext, useEffect, useState } from "react";
import { getUserToken } from "../contexts/AuthContext";
import Header from "../components/Header";
import config from "../config";
import { Link, useNavigate } from "react-router-dom";
import { CheckIcon } from "@heroicons/react/24/outline";
import { NotificationContext } from "../contexts/NotificationContext";

function ListNew() {
    const { addNotification } = useContext(NotificationContext);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        document.title = `GeoCMS\u00ae | New list`;
    }, []);

    async function postData() {
        const token = await getUserToken();
        const data = {
            name: name,
            description: description
        }

        const res = await fetch(`${config.backend_url_v2}/v3/cms/list`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(data)
        });

        if (res.status === 200) {
            addNotification('List was saved successfully', 'SUCCESS');
            navigate('/lists');
        } else {
            addNotification('Something went wrong :(', 'ERROR');
        }
    }

    function submitForm(event) {
        event.preventDefault();
        postData();
    };

    return (
        <Fragment>
            <Header heading="Add a new list" />

            <div className="flex flex-col max-w-5xl mx-auto pb-6 px-4 sm:px-6 lg:px-8">
                <form onSubmit={submitForm}>
                    <div className="mt-6 grid grid-cols-12 gap-y-6 gap-x-4">
                        <div className="sm:col-span-12">
                            <label htmlFor="username" className="block text-sm font-medium text-gray-700">
                                List name (in English)*
                            </label>

                            <div className="mt-1 flex rounded-md shadow-sm">
                                <input
                                    value={name}
                                    onInput={event => setName(event.target.value)}
                                    type="text"
                                    name="list-name"
                                    id="list-name"
                                    placeholder="Cities where you can surf"
                                    className="flex-1 focus:ring-blue-500 focus:border-blue-500 block w-full min-w-0 rounded-none rounded-md sm:text-sm border-gray-300"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-12">
                            <label htmlFor="username" className="block text-sm font-medium text-gray-700">
                                List description
                            </label>

                            <div className="mt-1 flex rounded-md shadow-sm">
                                <textarea
                                    value={description}
                                    onInput={event => setDescription(event.target.value)}
                                    type="text"
                                    name="list-description"
                                    id="list-description"
                                    placeholder="Describe the list"
                                    className="flex-1 focus:ring-blue-500 focus:border-blue-500 block w-full min-w-0 rounded-none rounded-md sm:text-sm border-gray-300"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="pt-5">
                        <div className="flex justify-end">
                            <Link
                                to='/lists'
                                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                                Cancel
                            </Link>

                            {(name) && <button
                                type="submit"
                                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                                <CheckIcon className="h-5 w-5 mr-1" />
                                Save
                            </button>}

                            {(!name) && <button
                                disabled
                                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-200">
                                <CheckIcon className="h-5 w-5 mr-1" />
                                Save
                            </button>}
                        </div>
                    </div>
                </form>
            </div>
        </Fragment>
    )
}

export default ListNew;