import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useContext } from "react";
import config from "../../config";
import { getUserToken } from "../../contexts/AuthContext";
import { NotificationContext } from "../../contexts/NotificationContext";
import { LocationContext } from "../../pages/LocationWrapper";

function LocationAlternateLine(props) {
    const alternate = props.alternate;
    const editing = props.editing;
    const [location, setLocation] = useContext(LocationContext);
    const { addNotification } = useContext(NotificationContext);

    function formatIcon(show) {
        if (show) {
            return (
                <CheckIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            )
        }
    }

    async function deleteAlternate() {
        const token = await getUserToken();
        const res = await fetch(`${config.backend_url}/v1/location/alternate`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                alternate_id: Number(alternate.id),
                geonames_id: Number(location.geonames_id)
            })
        });

        if (res.status === 204) {
            const filteredList = location.alternates.filter((item) => item.id !== alternate.id);
            let copy = { ...location };
            copy.alternates = filteredList;

            setLocation(copy);
            addNotification('Alternate was removed from the location successfully', 'SUCCESS');
        }
    }

    return (
        <tr key={alternate.id}>
            <td className="px-6 py-4 break-words max-w-sm">
                {alternate.name}

                {alternate.custom && <span className="ml-2 flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full">
                    custom
                </span>}
            </td>

            <td className="px-6 py-4 break-words max-w-sm">
                {alternate.language_code}
            </td>

            <td className="px-6 py-4 whitespace-nowrap">
                {formatIcon(alternate.preferred)}
            </td>

            <td className="px-6 py-4 whitespace-nowrap">
                {formatIcon(alternate.short)}
            </td>

            <td className="px-6 py-4 whitespace-nowrap">
                {formatIcon(alternate.colloquial)}
            </td>

            <td className="px-6 py-4 whitespace-nowrap">
                {formatIcon(alternate.historic)}
            </td>

            <td className="px-6 py-4 whitespace-nowrap">
                {formatIcon(alternate.show)}
            </td>

            {(editing && alternate.custom) && <td>
                <button
                    onClick={deleteAlternate}
                    type="button"
                    className="ml-2 inline-flex items-center px-2 py-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-300">
                    Remove
                    <XMarkIcon className="ml-1 h-5 w-5"></XMarkIcon>
                </button>
            </td>}
        </tr>
    )
}

export default LocationAlternateLine;