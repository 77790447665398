const dev = {
    backend_url_v2: "https://search.meteoplaza.com",
    backend_url_v3: "https://search.meteoplaza.com",
    meilisearch: "https://meilisearch-prod.meteoplaza.com",
    meilisearch_key: "d0d41f1935956b9961c6e607accfbb1abcfcee56bd6e4ebda3ff20c9e6737859",
    meilisearch_key_ski: "723fd5646f184fb71c088e9c36aa134fe1d53612b70e65483aa87d5183210607",
    storage_key: "GEO_CMS_LAST_VISITED",
    hmac_app_id: "32d7fd46-d0a0-45dc-9bc2-b7590510235d",
    hmac_api_key: "IXTVyEmuvKZbWXjRiAo7DncSfkfgIhx1wiiM3STWkhU=",
};

const prod = {
    backend_url_v2: "https://search.meteoplaza.com",
    backend_url_v3: "https://search.meteoplaza.com",
    meilisearch: "https://meilisearch-prod.meteoplaza.com",
    meilisearch_key: "d0d41f1935956b9961c6e607accfbb1abcfcee56bd6e4ebda3ff20c9e6737859",
    meilisearch_key_ski: "723fd5646f184fb71c088e9c36aa134fe1d53612b70e65483aa87d5183210607",
    storage_key: "GEO_CMS_LAST_VISITED",
    hmac_app_id: "32d7fd46-d0a0-45dc-9bc2-b7590510235d",
    hmac_api_key: "IXTVyEmuvKZbWXjRiAo7DncSfkfgIhx1wiiM3STWkhU=",
};

const config = process.env.NODE_ENV === 'production' ? prod : dev;

export default config;