import { useContext } from "react";
import { CircleMarker, MapContainer, Polyline, Popup, TileLayer } from "react-leaflet";
import { useEffect, useState } from "react";
import { roundTo } from "../../util/Format";
import { POIContext } from "../../pages/POIWrapper";
import { Link } from "react-router-dom";
import { getUserToken } from "../../contexts/AuthContext";
import { NotificationContext } from "../../contexts/NotificationContext";
import config from "../../config";
import EditRow from "../EditRow";
import { calculateDistance, LeafletHandler } from "../../util/Map";

function POIMap() {
    const [poi, setPOI] = useContext(POIContext);
    const [center, setCenter] = useState({lat: null, lon: null});
    const [zoom, setZoom] = useState(null);
    const { addNotification } = useContext(NotificationContext);
    const [editing, setEditing] = useState(false);

    useEffect(() => {
        setCenter({ lat: poi.latitude, lng: poi.longitude });
        setZoom(poi.zoom);
    }, [poi]);

    async function updatePOI() {
        const token = await getUserToken();
        const res = await fetch(`${config.backend_url_v2}/v2/POI/${poi.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                latitude: Number(roundTo(center.lat, 6)),
                longitude: Number(roundTo(center.lng, 6)),
                zoom: zoom,
                name: poi.name,
                geoname_id: poi.geoname_id
            })
        });

        if (res.status === 200) {
            let copy = { ...poi };
            copy.latitude = Number(roundTo(center.lat, 6));
            copy.longitude = Number(roundTo(center.lng, 6));
            copy.zoom = zoom;

            setPOI(copy);
            setEditing(false);
            addNotification('POI was editted successfully', 'SUCCESS');
        } else {
            const result = await res.json();
            addNotification(result.message, 'ERROR');
        }
    }

    function changeCenter(latlng) {
        if (editing) {
            setCenter(latlng);
        }
    }

    if (poi) {
        return (
            <section className="bg-white shadow overflow-hidden rounded-lg flex full-w flex-col my-6">
                <div className="flex items-center justify-between px-4 py-5 sm:px-6">
                    <div>
                        <div className="flex items-center">
                            <h2 className="text-2xl font-bold text-gray-900">
                                Map center and zoom
                            </h2>

                            <div className="flex ml-4">
                                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                    Lat {roundTo(center.lat, 6)}, Long {roundTo(center.lng, 6)}
                                </span>

                                {!editing && <span className="ml-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                    Zoom {poi.zoom}
                                </span>}

                                {editing && <span className="ml-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                    Zoom {zoom}
                                </span>}

                                <span className="ml-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                    Distance location {calculateDistance({ latitude: poi.location_latitude, longitude: poi.location_longitude }, { latitude: center.lat, longitude: center.lng })}km
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-center">
                        <EditRow
                            cancel={true}
                            buttonText="Save"
                            editState={editing}
                            save={() => { updatePOI() }}
                            editing={(state) => { setEditing(state) }} />
                    </div>
                </div>

                <div className="block h-half z-0">
                    <MapContainer center={[poi.latitude, poi.longitude]} zoom={poi.zoom}>
                        <TileLayer
                            attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors - &copy; <a href="https://maptiler.com/copyright">MapTiler</a>'
                            url="https://maps.meteoplaza.com/styles/topo/{z}/{x}/{y}.png" />

                        {center && poi && <Polyline pathOptions={{ color: '#989898' }} positions={[
                            [poi.location_latitude, poi.location_longitude],
                            center
                        ]} />}

                        {poi && <CircleMarker
                            center={[poi.location_latitude, poi.location_longitude]}
                            pathOptions={{ color: 'gray' }}
                            radius={10}>
                            <Popup>
                                <div className="flex flex-col">
                                    <span className="text-xs text-gray-400 ">Linked location</span>
                                    <Link className="underline cursor-pointer text-blue-800 hover:text-blue-900" to={`/locations/${poi.geonames_id}`}>{poi.location_name}</Link>
                                </div>
                            </Popup>
                        </CircleMarker>}

                        {center && <CircleMarker
                            center={center}
                            pathOptions={{ color: 'black' }}
                            radius={10}>
                        </CircleMarker>}

                        <LeafletHandler newCenter={(latlng) => changeCenter(latlng)} newZoom={(zoom) => setZoom(zoom)} />
                    </MapContainer>
                </div>
            </section>
        )
    } else {
        return null;
    }
}

export default POIMap;