import { Fragment, useContext, useEffect, useState } from "react";
import { getUserToken } from "../contexts/AuthContext";
import Header from "../components/Header";
import config from "../config";
import { CircleMarker, MapContainer, Polyline, TileLayer } from "react-leaflet";
import { Link, useNavigate } from "react-router-dom";
import { CheckIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon, PlusIcon, ArrowPathIcon, MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { NotificationContext } from "../contexts/NotificationContext";
import { calculateDistance, LeafletHandler } from "../util/Map";
import Error from "../components/Error";
import { formatPossibleEmpty } from "../util/Format";
import PopoverText from "../components/PopoverText";
import { wrapFetch } from "../util/Hmac";

function POINew() {
    const navigate = useNavigate();
    const [error] = useState(false);
    const { addNotification } = useContext(NotificationContext);
    const [center, setCenter] = useState({lat: null, lon: null});
    const [zoom, setZoom] = useState(5);
    const [name, setName] = useState('');
    const [query, setQuery] = useState('');
    const [input, setInput] = useState('');
    const [loading, setLoading] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [location, setLocation] = useState(null);

    useEffect(() => {
        document.title = `GeoCMS\u00ae | New POI`;
    }, []);

    async function postData() {
        const token = await getUserToken();
        const data = {
            name: name,
            latitude: Number(center.lat.toFixed(6)),
            longitude: Number(center.lng.toFixed(6)),
            zoom: Number(zoom),
            geoname_id: Number(location.geoname_id),
            category_id: 1
        }

        const res = await fetch(`${config.backend_url_v3}/v3/cms/poi`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(data)
        });

        if (res.status === 200) {
            addNotification('POI was saved successfully', 'SUCCESS');
            navigate('/pois');
        } else {
            const result = await res.json();
            addNotification(result.message, 'ERROR');
        }
    }

    useEffect(() => {
        async function getData() {
            try {
                setLoading(true);

                const result = await wrapFetch(`${config.backend_url_v3}/v3/weeronline/search?lang=en&search=${query}`);

                const locations = result.filter((item) => item.type === 'location');
                setSearchResults(locations);
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        }

        if (query && query.length > 0) {
            getData();
        }
    }, [query]);

    function submitForm(event) {
        event.preventDefault();
        postData();
    };

    function searchInput(event) {
        event.preventDefault();
        setQuery(input);
    };

    function setPOILocation(location) {
        setQuery('');
        setInput('');
        setSearchResults([]);
        setLocation(location);
    }

    if (error) {
        return (
            <Fragment>
                <Header heading="New POI" />

                <Error error={error} />
            </Fragment>
        )
    } else {
        return (
            <Fragment>
                <Header heading="Add a new place of interest" />

                <div className="flex flex-col max-w-5xl mx-auto pb-6 px-4 sm:px-6 lg:px-8">
                    <section className="mt-4 flex flex-col rounded-md shadow-sm">
                        <div className="flex">
                            <span className="block text-sm font-medium text-gray-700 pb-1">
                                Search for location
                            </span>

                            <PopoverText text="A place of interest is always attached to an existing location. From this location it will get the weather data." />
                        </div>

                        <form onSubmit={searchInput} className="flex relative items-stretch flex-grow focus-within:z-10">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </div>

                            <input
                                value={input}
                                onInput={event => setInput(event.target.value)}
                                type="search"
                                name="search"
                                id="search"
                                className="focus:ring-blue-500 focus:border-blue-500 block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300"
                                placeholder="Search for location name"
                            />

                            <button
                                type="submit"
                                disabled={loading}
                                className="-ml-px inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500">

                                {loading && <ArrowPathIcon className="animate-spin mr-1 h-4 w-4" />}
                                <span>Search</span>
                            </button>
                        </form>
                    </section>

                    {searchResults.length > 0 && <div>
                        <div className="flex items-center space-x-3 mb-2 mt-4">
                            <h2>Search results</h2>
                        </div>

                        <div className="bg-white border border-gray-300 overflow-hidden rounded-md">
                            <ul className="divide-y divide-gray-300">
                                {searchResults.map((item) => (
                                    <li key={item.geoname_id} className="p-4">
                                        <div className="flex items-center justify-between">
                                            <div className="flex items-center">
                                                <span>{item.name}</span>

                                                <p className="ml-4 text-sm font-medium text-gray-500 truncate">{item.country_name} - {formatPossibleEmpty(item.admin_name)}</p>
                                            </div>

                                            {item.location_id && <button
                                                disabled={loading}
                                                onClick={() => setPOILocation(item)}
                                                type="button"
                                                className="inline-flex items-center px-2 py-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                                                Use location
                                                <PlusIcon className="ml-1 h-5 w-5"></PlusIcon>
                                            </button>}
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    }

                    {searchResults.length === 0 && query.length > 0 && <div>
                        <p>Nothing found for "{query}"</p>
                    </div>}

                    <form onSubmit={submitForm}>
                        <div className="mt-6 grid grid-cols-12 gap-y-6 gap-x-4">
                            <div className="sm:col-span-6">
                                <label htmlFor="username" className="block text-sm font-medium text-gray-700">
                                    POI name
                                </label>

                                <div className="mt-1 flex rounded-md shadow-sm">
                                    <input
                                        value={name}
                                        onInput={event => setName(event.target.value)}
                                        type="text"
                                        name="poi-name"
                                        id="poi-name"
                                        placeholder="Efteling or Disneyland"
                                        className="flex-1 focus:ring-blue-500 focus:border-blue-500 block w-full min-w-0 rounded-none rounded-md sm:text-sm border-gray-300"
                                    />
                                </div>
                            </div>

                            {location && <div className="sm:col-span-6">
                                <span className="block text-sm font-medium text-gray-700">
                                    POI location
                                </span>

                                <div className="flex mt-2 items-center">
                                    <span className="block text-gray-900">
                                        {location.name}
                                    </span>

                                    <CheckCircleIcon className="ml-1 w-5 h-5 text-green-700" />
                                </div>
                            </div>}

                            {!location && <div className="sm:col-span-6">
                                <span className="block text-sm font-medium text-gray-700">
                                    POI location
                                </span>

                                <div className="flex mt-2 items-center">
                                    <span className="block text-gray-500">
                                        Search for a location
                                    </span>
                                </div>
                            </div>}
                        </div>

                        <div className="flex items-center mt-4 m1-2">
                            <h3 className="text-sm leading-6 font-medium text-gray-900">Click on the map to set the center of the new POI, you can also set the wanted zoom</h3>
                        </div>

                        <div className="bg-white shadow overflow-hidden sm:rounded-lg h-half">
                            <MapContainer center={[50, 4]} zoom={5}>
                                <TileLayer
                                    attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors - &copy; <a href="https://maptiler.com/copyright">MapTiler</a>'
                                    url="https://maps.meteoplaza.com/styles/topo/{z}/{x}/{y}.png"
                                />

                                <LeafletHandler newCenter={(latlng) => setCenter(latlng)} newZoom={(zoom) => setZoom(zoom)} />

                                {(center && location) && <Polyline pathOptions={{ color: '#989898' }} positions={[
                                    center,
                                    [location.latitude, location.longitude]
                                ]} />}

                                {center && <CircleMarker
                                    center={center}
                                    pathOptions={{ color: 'black' }}
                                    radius={10}>
                                </CircleMarker>}

                                {location && <CircleMarker
                                    center={[location.latitude, location.longitude]}
                                    pathOptions={{ color: 'gray' }}
                                    radius={10}>

                                </CircleMarker>}
                            </MapContainer>
                        </div>

                        {center && center.lat && center.lon && <div className="flex mt-6">
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                Lat {center.lat.toFixed(6)}, Long {center.lng.toFixed(6)}
                            </span>

                            <span className="ml-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                Zoom {zoom}
                            </span>

                            {location && <span className="ml-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                Distance location {calculateDistance({ latitude: location.latitude, longitude: location.longitude }, { latitude: center.lat, longitude: center.lng })}km
                            </span>}
                        </div>}

                        <div className="pt-5">
                            <div className="flex justify-end">
                                <Link
                                    to='/pois'
                                    className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                                    Cancel
                                </Link>

                                {(name && location && center && zoom) && <button
                                    type="submit"
                                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                                    <CheckIcon className="h-5 w-5 mr-1" />
                                    Save
                                </button>}

                                {(!name || !location || !center || !zoom) && <button
                                    disabled
                                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-200">
                                    <CheckIcon className="h-5 w-5 mr-1" />
                                    Save
                                </button>}
                            </div>
                        </div>
                    </form>
                </div>
            </Fragment>
        )
    }
}

export default POINew;