import { CheckIcon, PencilIcon, ArrowPathIcon, XMarkIcon } from "@heroicons/react/24/solid";

function EditRow(props) {
    if (props.editState) {
        return (
            <div>
                {props.cancel && <button
                    onClick={() => props.editing(false)}
                    type="button"
                    className="mr-4 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-400 hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
                    Cancel
                    <XMarkIcon className="ml-1 h-5 w-5" />
                </button>}

                <button
                    onClick={() => props.save()}
                    type="button"
                    className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                    {props.buttonText || 'Save'}
                    <CheckIcon className="ml-1 h-5 w-5"></CheckIcon>
                </button>
            </div>
        )
    } else if (props.loading) {
        return (
            <button
                disabled
                type="button"
                className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-300">
                Saving
                <ArrowPathIcon className="animate-spin ml-1 h-5 w-5" />
            </button>
        )
    } else {
        return (
            <button
                onClick={() => props.editing(true)}
                type="button"
                className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                Edit
                <PencilIcon className="ml-1 h-5 w-5" />
            </button>
        )
    }
}

export default EditRow;