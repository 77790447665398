import { Fragment, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import queryString from 'query-string';
import { detectInputType, getLatLonValues } from '../../util/DetectInputType';
import config from "../../config";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import SearchResultId from "./SearchResultId";
import SearchResultMap from "./SearchResultMap";
import { useLocation } from "react-router-dom";

function SearchDatabase() {
    const { search } = useLocation();
    const [input, setInput] = useState('');
    const [query, setQuery] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const navigate = useNavigate();
    const authValue = useContext(AuthContext);

    function searchInput(event) {
        event.preventDefault();
        changeSearch(input);
    };

    function changeSearch(query) {
        let params = queryString.parse(search);

        if (params.q !== query) {
            params.q = query;
            navigate(`/search?${queryString.stringify(params)}`);
        }
    }

    useEffect(() => {
        const params = queryString.parse(search);

        if (params.q && params.q.length > 0) {
            const queryString = decodeURI(params.q);

            setInput(queryString);
            setQuery(queryString);
        }
    }, [search]);

    useEffect(() => {
        async function getData() {
            try {
                setLoading(true);

                let url;
                const queryType = detectInputType(query);

                if (queryType === 'id') {
                    url = `${config.backend_url_v2}/v2/Wol/NL/Geos/${query}`;
                } else if (queryType === 'latlon') {
                    const latlon = getLatLonValues(query);
                    url = `https://search-nearby-proxy-develop.infoplaza.workers.dev?count=20&lang=en&lat=${latlon[0]}&lon=${latlon[1]}&radius=20000`
                }

                const res = await fetch(url);
                const data = await res.json();

                setSearchResults(data.hits);
                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        }

        if (query && query.length > 0 && authValue && authValue.user) {
            getData();
        }
    }, [query, navigate, authValue]);

    function handleMapClick(latlng) {
        const { lat, lng } = latlng;
        setInput(`${lat},${lng}`);
        setQuery(`${lat},${lng}`);
    }

    function showList() {
        if (loading) {
            return (
                <Fragment>
                    <div className="bg-gray-200 w-full animate-pulse h-16 rounded-lg pt-4 mb-4"></div>
                    <div className="bg-gray-200 w-full animate-pulse h-16 rounded-lg"></div>
                </Fragment>
            )
        } else if (searchResults && query.length > 0) {
            const queryType = detectInputType(query);

            if (queryType === 'id') {
                return (
                    <SearchResultId results={searchResults} />
                )
            } else if (queryType === 'latlon') {
                const latlon = getLatLonValues(query);
                return (
                    <SearchResultMap results={searchResults} lat={latlon[0]} lon={latlon[1]} onMapClick={handleMapClick} />
                )
            }
        } else if (error || (searchResults && searchResults.length === 0 && query.length > 0)) {
            return (
                <h2>No results found for "{query}"</h2>
            )
        }
    }

    return (
        <div className="Search">
            <div className="search-input flex flex-col max-w-7xl mx-auto pt-6 px-8">
                <div className="mt-1 flex rounded-md shadow-sm">
                    <form onSubmit={searchInput} className="relative flex items-stretch flex-grow focus-within:z-10">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </div>

                        <input
                            value={input}
                            onInput={event => setInput(event.target.value)}
                            type="search"
                            name="search"
                            id="search"
                            className="focus:ring-blue-500 focus:border-blue-500 block w-full rounded-none rounded-l-md pl-10 sm:text-sm border-gray-300"
                            placeholder="Search for Geoname ID or nearby locations with lat,lon"
                        />

                        <button
                            type="submit"
                            className="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500">
                            <span>Search</span>
                        </button>
                    </form>
                </div>
            </div>

            <div className="flex flex-col max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                {showList()}
            </div>
        </div>
    )
}

export default SearchDatabase;