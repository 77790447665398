import { useState, Fragment, useContext} from "react";
import { Link } from "react-router-dom";
import config from "../../config";
import { getUserToken } from "../../contexts/AuthContext";
import { NotificationContext } from "../../contexts/NotificationContext";
import { CountryContext } from "../../pages/CountryWrapper";
import EditRow from "../EditRow";

function CountryMeta() {
    const { addNotification } = useContext(NotificationContext);
    const [country, setCountry] = useContext(CountryContext);
    const [editing, setEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [weeronlineId, setWeeronlineId] = useState(country.weeronline_id || '');
    const [weeronlineUrl, setWeeronlineUrl] = useState(country.weeronline_url || '');

    async function postData() {
        const token = await getUserToken();

        setLoading(true);

        const res = await fetch(`${config.backend_url_v2}/v2/Country/Mapping/${Number(country.geonames_id)}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                weeronline_id: Number(weeronlineId),
                weeronline_url: weeronlineUrl
            })
        });

        setLoading(false);

        if (res.status === 200) {
            setEditing(false);

            let copy = { ...country };
            copy.weeronline_id = weeronlineId;
            copy.weeronline_url = weeronlineUrl;
            setCountry(copy);

            addNotification('Country data was mapped successfully', 'SUCCESS');
        } else {
            const result = await res.json();
            addNotification(result.message, 'ERROR');
        }
    }

    function formatGeonames(id) {
        if (id) {
            return (
                <a target="_blank" rel="noreferrer" href={`https://www.geonames.org/${id}`} className="underline cursor-pointer text-blue-800 hover:text-blue-900">{id}</a>
            )
        } else {
            return (
                <span>Not linked</span>
            )
        }
    }

    function formatWeeronline(url, id) {
        if (editing) {
            return (
                <Fragment>
                    <div>
                        <label htmlFor="geonames_id" className="block text-sm font-medium text-gray-700">
                            Weeronline ID
                        </label>

                        <div className="mt-1">
                            <input
                                value={id}
                                onChange={(e) => setWeeronlineId(e.target.value)}
                                type="text"
                                name="geonames_id"
                                id="geonames_id"
                                className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                placeholder="53" />
                        </div>
                    </div>

                    <div className="mt-4">
                        <label htmlFor="geonames_id" className="block text-sm font-medium text-gray-700">
                            Weeronline URL
                        </label>

                        <div className="mt-1">
                            <input
                                value={url}
                                onChange={(e) => setWeeronlineUrl(e.target.value)}
                                type="text"
                                name="geonames_id"
                                id="geonames_id"
                                className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                placeholder="/Afrika/Togo/53" />
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            if (url && url !== 'NULL' && id) {
                return (
                    <a target="_blank" rel="noreferrer" href={`https://weeronline.nl${url}`} className="underline cursor-pointer text-blue-800 hover:text-blue-900">{id}</a>
                )
            } else if (id) {
                return (
                    <span>{id}</span>
                )
            } else {
                return (
                    <span>Not linked</span>
                )
            }
        }
    }

    return (
        <section className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="flex items-center justify-between px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Country mapping</h3>

                <EditRow
                    cancel={true}
                    save={() => postData()}
                    editState={editing}
                    loading={loading}
                    editing={(state) => setEditing(state)}></EditRow>
            </div>

            <div className="border-t border-gray-200">
                <dl>
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                            Geonames ID
                        </dt>

                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {formatGeonames(country.geonames_id)}
                        </dd>
                    </div>

                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                            Weeronline ID
                        </dt>

                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {formatWeeronline(weeronlineUrl, weeronlineId)}
                        </dd>
                    </div>

                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                           Admin 1
                        </dt>

                        <dd className="mt-1 text-sm underline cursor-pointer text-blue-800 hover:text-blue-900">
                            <Link to={`/admins/${country.code.toLowerCase()}`}>Edit admins of this country</Link>
                        </dd>
                    </div>
                </dl>
            </div>
        </section>
    )
}

export default CountryMeta;