import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import config from "../../config";
import { getUserToken } from "../../contexts/AuthContext";
import { NotificationContext } from "../../contexts/NotificationContext";
import { POIContext } from "../../pages/POIWrapper";
import EditRow from "../EditRow";
import POISearch from "./POISearch";

function POIDetails() {
    const [poi, setPOI] = useContext(POIContext);
    const [name, setName] = useState(poi.name || '');
    const { addNotification } = useContext(NotificationContext);
    const [editing, setEditing] = useState(false);

    async function updatePOI() {
        const token = await getUserToken();
        const res = await fetch(`${config.backend_url_v2}/v3/cms/poi/${poi.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                latitude: poi.latitude,
                longitude: poi.longitude,
                zoom: poi.zoom,
                name: name,
                geoname_id: poi.geoname_id
            })
        });

        if (res.status === 200) {
            let copy = { ...poi };
            copy.name = name;

            setPOI(copy);
            setEditing(false);
            addNotification('POI was editted successfully', 'SUCCESS');
        } else {
            const result = await res.json();
            addNotification(result.message, 'ERROR');
        }
    }

    return (
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="flex justify-between px-4 py-5 sm:px-6">
                <div className="flex items-center">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">POI details</h3>
                </div>

                <EditRow
                    cancel={true}
                    editState={editing}
                    save={() => updatePOI()}
                    editing={(state) => setEditing(state)}></EditRow>
            </div>

            <div className="border-t border-gray-200">
                <dl>
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                            Name
                        </dt>

                        {!editing && <dd className="mt-1 sm:mt-0 sm:col-span-2">
                            <span className="text-sm text-gray-900">{poi.name}</span>
                        </dd>}

                        {editing && <input
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            type="text"
                            name="name"
                            id="name"
                            className="focus:ring-blue-500 focus:border-blue-500 block w-full rounded-none rounded-md sm:text-sm border-gray-300"
                            placeholder="Efteling" />}
                    </div>
                </dl>

                <dl>
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                            Linked location
                        </dt>

                        {!editing && <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            <Link to={`/locations/${poi.geoname_id}`} className="underline cursor-pointer text-blue-800 hover:text-blue-900">{poi.location_name}</Link>
                        </dd>}

                        {editing && <POISearch />}
                    </div>
                </dl>

                <dl>
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                            Country
                        </dt>

                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            <Link to={`/countries/${poi.country_id}`} className="underline cursor-pointer text-blue-800 hover:text-blue-900">{poi.country_name}</Link>
                        </dd>
                    </div>
                </dl>

                <dl>
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                            Category
                        </dt>

                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            <p className="text-sm text-gray-900">{poi.category_name}</p>
                        </dd>
                    </div>
                </dl>
            </div>
        </div>
    )
}

export default POIDetails;