import { useContext, useEffect, useState } from "react";
import Header from "../Header";
import { useParams } from "react-router-dom";
import LocationDetails from "./LocationDetails";
import LocationMapping from "./LocationMapping";
import LocationAlternate from "./LocationAlternate";
import config from "../../config";
import { AuthContext } from "../../contexts/AuthContext";
import Error from "../Error";
import { addToLocationsList } from "../../util/Localstorage";
import { LocationContext } from "../../pages/LocationWrapper";
import LocationMap from "./LocationMap";
import { generateId } from "../../util/Random";
import { wrapFetch } from "../../util/Hmac";

function LocationDetail() {
    const { id } = useParams();
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [location, setLocation] = useContext(LocationContext);
    const authValue = useContext(AuthContext);

    useEffect(() => {
        document.title = `GeoCMS\u00ae | ${location.name}`;
    }, [location]);

    useEffect(() => {
        async function getData() {
            try {
                const result = await wrapFetch(`${config.backend_url_v3}/v3/cms/location/${id}?cb=${generateId(8)}`);

                setLocation(result);

                addToLocationsList({
                    name: result.name,
                    country_name: result.country_name,
                    geoname_id: result.geoname_id,
                    population: result.population
                });
            } catch (error) {
                setError(error);
            } finally {
                setIsLoaded(true);
            }
        }

        if (authValue && authValue.user) {
            getData();
        }
    }, [id, setLocation, authValue]);

    if (error) {
        return (
            <div className="Location">
                <Header heading="Location" />

                <Error error={error} />
            </div>
        )
    } else if (!isLoaded) {
        return (
            <div className="Location">
                <Header heading="Location" loading={true} />

                <div className="flex flex-col max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                    <div className="bg-gray-200 w-full animate-pulse h-64 rounded-lg mb-4"></div>
                </div>
            </div>
        )
    } else if (location && location.name) {
        return (
            <div className="Location">
                <Header heading={location.name} />

                <div className="flex flex-col max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">

                    <LocationMapping />

                    <LocationDetails location={location} />

                    <LocationMap />

                    <LocationAlternate />
                </div>
            </div>
        )
    } else {
        return (
            <div className="Location">
                <Header heading="Oops" />

                <div className="flex flex-col max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                    <p>This location was not found or has an invalid ID</p>
                </div>
            </div>
        )
    }
}

export default LocationDetail;