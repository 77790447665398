import { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { AuthContext, getUserToken } from "../../contexts/AuthContext";
import Header from "../Header";
import config from "../../config";
import { RegionContext } from "../../pages/RegionWrapper";
import RegionTranslations from "./RegionTranslations";
import RegionMap from "./RegionMap";
import Error from "../Error";
import { NotificationContext } from "../../contexts/NotificationContext";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { ModalContext } from "../../contexts/ModalContext";
import { generateId } from "../../util/Random";
import { wrapFetch } from "../../util/Hmac";
import RegionLists from "./RegionLists";

function RegionDetail() {
    const { id } = useParams();
    const navigate = useNavigate();
    const { addNotification } = useContext(NotificationContext);
    const { openModal } = useContext(ModalContext);
    const [region, setRegion] = useContext(RegionContext);
    const authValue = useContext(AuthContext);
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);

    async function deleteRegion() {
        const token = await getUserToken();
        const res = await fetch(`${config.backend_url_v2}/v3/cms/region/${region.id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });

        if (res.status === 200) {
            navigate('/regions');
            addNotification('Region was removed successfully', 'SUCCESS');
        } else {
            const result = await res.json();
            addNotification(result.message, 'ERROR');
        }
    }

    useEffect(() => {
        async function getData() {
            try {
                const result = await wrapFetch(`${config.backend_url_v3}/v3/cms/region/${id}?cb=${generateId(6)}`);

                // Sort translations by language code
                result.translations.sort((a, b) => {
                    if (a.language_code < b.language_code) {
                        return -1;
                    } else if (a.language_code > b.language_code) {
                        return 1;
                    }

                    return 0;
                });

                setRegion(result);
            } catch (error) {
                setError(error);
            } finally {
                setIsLoaded(true);
            }
        }

        if (authValue && authValue.user) {
            getData();
        }
    }, [id, setRegion, authValue]);

    if (error) {
        return (
            <div className="Region">
                <Header heading="Region" />

                <Error error={error} />
            </div>
        )
    } else if (!isLoaded) {
        return (
            <div className="Region">
                <Header heading="Region" loading={true} />

                <div className="flex flex-col max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                    <div className="bg-gray-200 w-full animate-pulse h-64 rounded-lg mb-4"></div>
                </div>
            </div>
        )
    } else if (region) {
        return (
            <div className="Region">
                <Header heading={region.name_default}>
                    <button
                        onClick={() => {openModal(`You are about to remove ${region.name_default}`, 'Are you sure you want to remove this region and its translations? You can\'t undo this action later.', 'Cancel', 'Remove', () => deleteRegion())}}
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-300">
                        Remove
                        <XMarkIcon className="ml-1 h-5 w-5" />
                    </button>
                </Header>

                <div className="flex flex-col max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                    <RegionTranslations />

                    <RegionLists />

                    <RegionMap />
                </div>
            </div>
        )
    }
}

export default RegionDetail;