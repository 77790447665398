import { createContext, useState } from "react";
import RegionDetail from "../components/region/RegionDetail";

export const RegionContext = createContext({
    region: {},
    setRegion: () => {}
});

function RegionWrapper() {
    const [region, setRegion] = useState({});

    return (
        <RegionContext.Provider value={[region, setRegion]}>
            <RegionDetail></RegionDetail>
        </RegionContext.Provider>
    )
}

export default RegionWrapper;