import { useState } from "react";
import { Fragment } from "react";
import { useContext } from "react";
import config from "../../config";
import { getUserToken } from "../../contexts/AuthContext";
import { NotificationContext } from "../../contexts/NotificationContext";
import { LocationContext } from "../../pages/LocationWrapper";
import EditRow from "../EditRow";

function LocationMapping() {
    const { addNotification } = useContext(NotificationContext);
    const [editing, setEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [location] = useContext(LocationContext);
    const [weeronlineId] = useState(location.weeronline_id || '');
    const [weeronlineIndex] = useState(location.weeronline_index || '');
    const [weeronlineUrl, setWeeronlineUrl] = useState(location.weeronline_url || '');

    async function postData() {
        const token = await getUserToken();
        const data = {
            weeronline_id: Number(weeronlineId),
            weeronline_url: weeronlineUrl,
            weeronline_index: Boolean(weeronlineIndex)
        }

        setLoading(true);

        const res = await fetch(`${config.backend_url_v2}/v2/Location/Mapping/${Number(location.geoname_id)}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(data)
        });

        setLoading(false);

        if (res.status === 200) {
            setEditing(false);
            addNotification('Location was mapped successfully', 'SUCCESS');
        } else {
            const result = await res.json();
            addNotification(result.message, 'ERROR');
        }
    }

    function formatGeonames(id) {
        if (id) {
            return (
                <a target="_blank" rel="noreferrer" href={`https://www.geonames.org/${id}`} className="underline cursor-pointer text-blue-800 hover:text-blue-900">{id}</a>
            )
        } else {
            return (
                <span>Not linked</span>
            )
        }
    }

    function formatWeerplaza(url, id) {
        if (url && id) {
            return (
                <a target="_blank" rel="noreferrer" href={`https://weerplaza.nl${url}`} className="underline cursor-pointer text-blue-800 hover:text-blue-900">{id}</a>
            )
        } else if (id) {
            return (
                <span>{id}</span>
            )
        } else {
            return (
                <span>Not linked</span>
            )
        }
    }

    function formatWeeronline(url, id) {
        if (editing) {
            return (
                <Fragment>
                    <div>
                        <label htmlFor="geonames_id" className="block text-sm font-medium text-gray-700">
                            Weeronline ID
                        </label>

                        <span>{id}</span>
                    </div>

                    <div className="mt-4">
                        <label htmlFor="geonames_id" className="block text-sm font-medium text-gray-700">
                            Weeronline URL
                        </label>

                        <div className="mt-1">
                            <input
                                value={url}
                                onChange={(e) => setWeeronlineUrl(e.target.value)}
                                type="text"
                                name="geonames_id"
                                id="geonames_id"
                                className="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                placeholder="/Europa/Nederland/Utrecht/343434" />
                        </div>
                    </div>
                </Fragment>
            )
        } else {
            if (url && id) {
                return (
                    <a target="_blank" rel="noreferrer" href={`https://weeronline.nl${url}`} className="underline cursor-pointer text-blue-800 hover:text-blue-900">{id}</a>
                )
            } else if (id) {
                return (
                    <span>{id}</span>
                )
            } else {
                return (
                    <span>Not linked</span>
                )
            }
        }
    }

    return (
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="flex items-center justify-between px-4 py-5 sm:px-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">Location mapping</h3>

                <EditRow
                    cancel={true}
                    loading={loading}
                    editState={editing}
                    save={() => postData()}
                    editing={(state) => setEditing(state)}></EditRow>
            </div>

            <div className="border-t border-gray-200">
                <dl>
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                            Geonames ID
                        </dt>

                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {formatGeonames(location.geoname_id)}
                        </dd>
                    </div>

                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                            Weeronline ID
                        </dt>

                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {formatWeeronline(weeronlineUrl, weeronlineId)}
                        </dd>
                    </div>

                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                            Weerplaza ID
                        </dt>

                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {formatWeerplaza('', location.weerplaza_id)}
                        </dd>
                    </div>

                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                            Weeronline Indexed
                        </dt>

                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {(location.weeronline_index ? 'Yes' : 'No')}
                        </dd>
                    </div>

                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                            Weerplaza Indexed
                        </dt>

                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {(location.weerplaza_index ? 'Yes' : 'No')}
                        </dd>
                    </div>
                </dl>
            </div>
        </div>
    )
}

export default LocationMapping;