import { useContext } from "react";
import { CircleMarker, MapContainer, Polyline, Popup, TileLayer } from "react-leaflet";
import { useEffect, useState } from "react";
import { roundTo } from "../../util/Format";
import { EventContext } from "../../pages/EventWrapper";
import { Link } from "react-router-dom";
import { getUserToken } from "../../contexts/AuthContext";
import { NotificationContext } from "../../contexts/NotificationContext";
import config from "../../config";
import EditRow from "../EditRow";
import { calculateDistance, LeafletHandler } from "../../util/Map";
import { generateId } from "../../util/Random";
import { wrapFetch } from "../../util/Hmac";

function EventMap() {
    const [event, setEvent] = useContext(EventContext);
    const [center, setCenter] = useState({lat: null, lon: null});
    const [zoom, setZoom] = useState(null);
    const { addNotification } = useContext(NotificationContext);
    const [editing, setEditing] = useState(false);
    const [location, setLocation] = useState(null);

    useEffect(() => {
        if (event && event.latitude && event.longitude) {
            setCenter({ lat: event.latitude, lng: event.longitude });
            setZoom(event.zoom);

            async function getData(geoname_id) {
                try {
                    const result = await wrapFetch(`${config.backend_url_v3}/v3/cms/location/${geoname_id}?cb=${generateId(8)}`);
                    setLocation(result);
                } catch (error) {
                    console.log(error);
                }
            }

            getData(event.geoname_id);
        }
    }, [event]);

    async function updateEvent() {
        const token = await getUserToken();
        const res = await fetch(`${config.backend_url_v3}/v3/cms/event/${event.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                latitude: Number(roundTo(center.lat, 6)),
                longitude: Number(roundTo(center.lng, 6)),
                zoom: zoom,
                name: event.name,
                geoname_id: event.geoname_id,
                category_id: event.category_id,
            })
        });

        if (res.status === 200) {
            let copy = { ...event };
            copy.latitude = Number(roundTo(center.lat, 6));
            copy.longitude = Number(roundTo(center.lng, 6));
            copy.zoom = zoom;

            setEvent(copy);
            setEditing(false);
            addNotification('Event was editted successfully', 'SUCCESS');
        } else {
            const result = await res.json();
            addNotification(result.message, 'ERROR');
        }
    }

    function changeCenter(latlng) {
        if (editing) {
            setCenter(latlng);
        }
    }

    if (!event || !event.latitude || !event.longitude) {
        return null;
    }

    if (event) {
        return (
            <section className="bg-white shadow overflow-hidden rounded-lg flex full-w flex-col my-6">
                <div className="flex items-center justify-between px-4 py-5 sm:px-6">
                    <div>
                        <div className="flex items-center">
                            <h2 className="text-2xl font-bold text-gray-900">
                                Map center and zoom
                            </h2>

                            <div className="flex ml-4">
                                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                    Lat {roundTo(center.lat, 6)}, Long {roundTo(center.lng, 6)}
                                </span>

                                {!editing && <span className="ml-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                    Zoom {event.zoom}
                                </span>}

                                {editing && <span className="ml-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                    Zoom {zoom}
                                </span>}

                                {location && <span className="ml-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                    Distance location {calculateDistance({ latitude: location.latitude, longitude: location.longitude }, { latitude: center.lat, longitude: center.lng })}km
                                </span>}
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-center">
                        <EditRow
                            cancel={true}
                            buttonText="Save"
                            editState={editing}
                            save={() => { updateEvent() }}
                            editing={(state) => { setEditing(state) }} />
                    </div>
                </div>

                <div className="block h-half z-0">
                    <MapContainer center={[event.latitude, event.longitude]} zoom={event.zoom}>
                        <TileLayer
                            attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors - &copy; <a href="https://maptiler.com/copyright">MapTiler</a>'
                            url="https://maps.meteoplaza.com/styles/topo/{z}/{x}/{y}.png" />

                        {center && event && location && <Polyline pathOptions={{ color: '#989898' }} positions={[
                            [location.latitude, location.longitude],
                            center
                        ]} />}

                        {location && <CircleMarker
                            center={[location.latitude, location.longitude]}
                            pathOptions={{ color: 'gray' }}
                            radius={10}>
                            <Popup>
                                <div className="flex flex-col">
                                    <span className="text-xs text-gray-400 ">Linked location</span>
                                    <Link className="underline cursor-eventnter text-blue-800 hover:text-blue-900" to={`/locations/${event.geoname_id}`}>{location.name}</Link>
                                </div>
                            </Popup>
                        </CircleMarker>}

                        {center && <CircleMarker
                            center={center}
                            pathOptions={{ color: 'black' }}
                            radius={10}>
                        </CircleMarker>}

                        <LeafletHandler newCenter={(latlng) => changeCenter(latlng)} newZoom={(zoom) => setZoom(zoom)} />
                    </MapContainer>
                </div>
            </section>
        )
    }
}

export default EventMap;