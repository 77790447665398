import { Popover } from '@headlessui/react';
import { QuestionMarkCircleIcon } from "@heroicons/react/24/solid";

function PopoverText(props) {
    return (
        <Popover className="relative flex items-center ml-1">
            <Popover.Button>
                <QuestionMarkCircleIcon className="w-4 h-4" />
            </Popover.Button>

            <Popover.Panel className="absolute flex items-center max-w-xs z-50 ml-6 left-0 top-0 rounded-lg bg-gray-800 rounded-lg px-2 py-1 transform -translate-y-1/4">
                <span className="inline-block w-80 text-sm text-white">{props.text}</span>
            </Popover.Panel>
        </Popover>
    )
}

export default PopoverText;