import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Error from "../components/Error";
import Header from "../components/Header";
import config from "../config";
import { AuthContext } from "../contexts/AuthContext";
import { wrapFetch } from "../util/Hmac";

function Admins() {
    const { id } = useParams();
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [admins, setAdmins] = useState([]);
    const authValue = useContext(AuthContext);

    useEffect(() => {
        document.title = `GeoCMS\u00ae | Admins`;
    }, []);

    useEffect(() => {
        async function getData() {
            try {
                const res = await wrapFetch(`${config.backend_url_v2}/v2/admin/country/${id.toLowerCase()}`);

                setAdmins(res);
                setIsLoaded(true);
            } catch (error) {
                setError(error);
                setIsLoaded(true);
            }
        }

        if (authValue && authValue.user) {
            getData();
        }
    }, [authValue, id]);

    if (error) {
        return (
            <div className="Admins">
                <Header heading="Admins" />

                <Error error={error} />
            </div>
        )
    } else if (!isLoaded) {
        return (
            <div className="Admins">
                <Header heading="Admins" />

                <div className="flex flex-col max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                    <div className="bg-gray-300 w-full animate-pulse h-16 rounded-lg mb-4"></div>
                    <div className="bg-gray-300 w-full animate-pulse h-16 rounded-lg"></div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="Admins">
                <Header heading="Admins" />

                <div className="flex flex-col max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-white">
                                        <tr>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Name (EN)
                                            </th>

                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Code
                                            </th>

                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Translations
                                            </th>

                                            <th scope="col" className="relative px-6 py-3">
                                                <span className="sr-only">Edit</span>
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {admins.map(admin => (
                                            <tr key={admin.geonames_id}>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <Link to={`/admin/${admin.geonames_id}`} className="underline cursor-pointer">{admin.name}</Link>
                                                </td>

                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    {admin.code}
                                                </td>

                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                                    <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                        {admin.translations}
                                                    </span>
                                                </td>

                                                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                    <Link to={`/admin/${admin.geonames_id}`} className="underline cursor-pointer text-blue-800 hover:text-blue-900">View</Link>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Admins;