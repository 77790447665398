import { createContext, useState } from "react";
import LocationDetail from "../components/location/LocationDetail";

export const LocationContext = createContext({
    location: {},
    setLocation: () => {}
});

function LocationWrapper() {
    const [location, setLocation] = useState({});

    return (
        <LocationContext.Provider value={[location, setLocation]}>
            <LocationDetail />
        </LocationContext.Provider>
    )
}

export default LocationWrapper;