import { useContext, useEffect, useState } from "react";
import { DateTimePicker } from "react-rainbow-components";
import { Link } from "react-router-dom";
import RichTextEditor from "react-rte";
import config from "../../config";
import { getUserToken } from "../../contexts/AuthContext";
import { ModalContext } from "../../contexts/ModalContext";
import { NotificationContext } from "../../contexts/NotificationContext";
import { EventContext } from "../../pages/EventWrapper";
import EditRow from "../EditRow";
import EventSearch from "./EventSearch";
import { wrapFetch } from "../../util/Hmac";

const editorConfig = {
    display: ['INLINE_STYLE_BUTTONS', 'LINK_BUTTONS'],
    INLINE_STYLE_BUTTONS: [
        {label: 'Bold', style: 'BOLD'},
        {label: 'Italic', style: 'ITALIC'}
    ]
}

function EventDetails() {
    const { openModal } = useContext(ModalContext);
    const { addNotification } = useContext(NotificationContext);
    const [event, setEvent] = useContext(EventContext);
    const [editor, setEditor] = useState(RichTextEditor.createValueFromString(event.description || '', 'html'));
    const [name, setName] = useState(event.name || '');
    const [description, setDescription] = useState(event.description || '');
    const [startDate, setStartDate] = useState(event.start_date || '');
    const [endDate, setEndDate] = useState(event.end_date || '');
    const [url, setUrl] = useState(event.url || '');
    const [editing, setEditing] = useState(false);
    const [gptLoading, setGptLoading] = useState(false);
    const [categoryList, setCategoryList] = useState([]);

    useEffect(() => {
        if (event.id) {
            // Get category list
            async function getCategoryList() {
                const result = await wrapFetch(`${config.backend_url_v3}/v3/cms/eventcategory`);

                setCategoryList(result);
            }

            getCategoryList();
        }
    }, [event.id]);

    function countWords(html) {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = html;
        const str = tempDiv.textContent || '';

        return str.split(' ')
            .filter((n) => { return n !== '' })
            .length;
    }

    async function updateEvent() {
        const token = await getUserToken();
        const res = await fetch(`${config.backend_url_v3}/v3/cms/event/${event.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                latitude: event.latitude,
                longitude: event.longitude,
                zoom: event.zoom,
                name: name,
                geoname_id: event.geoname_id,
                description: editor.toString('html'),
                url: url,
                category_id: event.category_id,
                start_date: startDate,
                end_date: endDate
            })
        });

        if (res.status === 200) {
            let copy = { ...event };
            copy.name = name;

            setDescription(editor.toString('html'));
            setEvent(copy);
            setEditing(false);
            addNotification('Event was editted successfully', 'SUCCESS');
        } else {
            const result = await res.json();
            addNotification(result.message, 'ERROR');
        }
    }

    async function getChatText() {
        setGptLoading(true);
        const apiKey = 'RrFL6qpcqyXl7WysRgqe';
        const res = await fetch('https://ask-chat-gpt.infoplaza.workers.dev', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'api-key': `${apiKey}`
            },
            body: JSON.stringify({
                type: event.category_name,
                entity: event.name,
            })
        });

        setGptLoading(false);

        if (res.status === 200) {
            const result = await res.json();
            const answer = result.choices[0].message.content;

            const newEditor = RichTextEditor.createValueFromString(answer, 'html');
            setEditor(newEditor);

            addNotification('New event description was generated', 'SUCCESS');
        } else {
            addNotification('Something went wrong', 'ERROR');
        }
    }

    function updateCategory(category) {
        let copy = { ...event };
        copy.category_name = category.name;
        copy.category_id = category.id;

        setEvent(copy);
    }

    return (
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="flex justify-between px-4 py-5 sm:px-6">
                <div className="flex items-center">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Event details</h3>
                </div>

                <EditRow
                    cancel={true}
                    editState={editing}
                    save={() => updateEvent()}
                    editing={(state) => setEditing(state)}></EditRow>
            </div>

            <div className="border-t border-gray-200">
                <dl>
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                            Name
                        </dt>

                        {!editing && <dd className="mt-1 sm:mt-0 sm:col-span-2">
                            <span className="text-sm text-gray-900">{event.name}</span>
                        </dd>}

                        {editing && <input
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            type="text"
                            name="name"
                            id="name"
                            className="focus:ring-blue-500 focus:border-blue-500 block w-full rounded-none rounded-md sm:text-sm border-gray-300"
                            placeholder="Infoplaza BBQ" />}
                    </div>
                </dl>

                <dl>
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                            Linked location
                        </dt>

                        {!editing && <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            <Link to={`/locations/${event.geoname_id}`} className="underline cursor-eventnter text-blue-800 hover:text-blue-900">{event.location_name}</Link>
                        </dd>}

                        {editing && <EventSearch />}
                    </div>
                </dl>

                <dl>
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                            Country
                        </dt>

                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            <Link to={`/countries/${event.country_id}`} className="underline cursor-eventnter text-blue-800 hover:text-blue-900">{event.country_name}</Link>
                        </dd>
                    </div>
                </dl>

                <dl>
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                            Category
                        </dt>

                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {!editing && <p>{event.category_name}</p>}

                            {editing && <select
                                value={event.category_id}
                                onChange={(e) => updateCategory({ id: e.target.value, name: e.target.options[e.target.selectedIndex].text })}
                                id="category"
                                name="category"
                                className="focus:ring-blue-500 focus:border-blue-500 block w-full rounded-none rounded-md sm:text-sm border-gray-300">
                                {categoryList.map((category) => {
                                    return <option key={category.id} value={category.id}>{category.name}</option>
                                })}
                            </select>}
                        </dd>
                    </div>
                </dl>

                <dl>
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="flex align-items text-sm font-medium text-gray-500">
                            Start date
                        </dt>

                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {!editing && <p>{new Date(startDate).toLocaleString('nl', {
                                year: 'numeric',
                                month: 'long',
                                day: '2-digit',
                                hour: 'numeric',
                                minute: 'numeric'
                            })}</p>}

                            {editing && <DateTimePicker
                                locale="nl-NL"
                                hour24={true}
                                value={startDate}
                                borderRadius="square"
                                onChange={value => setStartDate(value)} />}
                        </dd>
                    </div>
                </dl>

                <dl>
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                            End date
                        </dt>

                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {!editing && <p>{new Date(endDate).toLocaleString('nl', {
                                year: 'numeric',
                                month: 'long',
                                day: '2-digit',
                                hour: 'numeric',
                                minute: 'numeric'
                            })}</p>}

                            {editing && <DateTimePicker
                                locale="nl-NL"
                                hour24={true}
                                value={endDate}
                                borderRadius="square"
                                onChange={value => setEndDate(value)} />}
                        </dd>
                    </div>
                </dl>

                <dl>
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                            Website
                        </dt>

                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {!editing && <Link to={url} className="underline cursor-eventnter text-blue-800 hover:text-blue-900">{url}</Link>}

                            {editing && <input
                                value={url}
                                onChange={(e) => setUrl(e.target.value)}
                                type="text"
                                name="url"
                                id="url"
                                className="focus:ring-blue-500 focus:border-blue-500 block w-full rounded-none rounded-md sm:text-sm border-gray-300"
                                placeholder="https://example.com" />}
                        </dd>
                    </div>
                </dl>

                <dl>
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                            Description
                        </dt>

                         <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            {!editing && <div className="event-description" dangerouslySetInnerHTML={{__html: description}}></div>}

                            {editing && <div className="flex align-center">
                                <button
                                    disabled={gptLoading}
                                    className="flex align-items disabled:opacity-25 rounded-md bg-blue-600 py-2 px-3 mb-2 mr-2 text-sm text-white shadow-sm hover:bg-blue-500"
                                    onClick={() => openModal(`You are generating a text`, 'Are you sure you want to generate a text? This will overwrite everything in the textbox.', 'Cancel', 'Get text', () => getChatText())}
                                    >
                                    <span>Autogenerate Description</span>

                                    {gptLoading && <svg className="animate-spin -mr-1 ml-2 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <path fill="white" d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"/>
                                    </svg>}
                                </button>
                            </div>}

                            {editing &&
                                <RichTextEditor
                                    className="event-description"
                                    toolbarConfig={editorConfig}
                                    value={editor}
                                    onChange={(value) => setEditor(value)} />
                            }

                            {editing && <div className="mt-2">about {countWords(editor.toString('html'))} words</div>}
                        </dd>
                    </div>
                </dl>
            </div>
        </div>
    )
}

export default EventDetails;