import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import Header from "../components/Header";
import config from "../config";
import { latinize } from "../util/Format";
import { CalendarIcon, GlobeEuropeAfricaIcon, PlusIcon, XCircleIcon } from "@heroicons/react/24/solid";
import Error from "../components/Error";
import PopoverMap from "../components/PopoverMap";
import { generateId } from "../util/Random";
import { wrapFetch } from "../util/Hmac";

function POIs() {
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [POIs, setPOIs] = useState([]);
    const [filter, setFilter] = useState('');
    const [filteredPOIs, setFilteredPOIs] = useState([]);
    const authValue = useContext(AuthContext);

    useEffect(() => {
        document.title = "GeoCMS\u00ae | POI's";
    }, []);

    useEffect(() => {
        setFilteredPOIs(POIs);
    }, [POIs]);

    useEffect(() => {
        const newList = POIs.filter((POI) => latinize(POI.name).toLowerCase().includes(filter.toLowerCase()));
        setFilteredPOIs(newList);
    }, [POIs, filter]);

    useEffect(() => {
        async function getData() {
            try {
                const result = await wrapFetch(`${config.backend_url_v3}/v3/cms/poi?cb=${generateId(6)}`);

                setPOIs(result);
                setIsLoaded(true);
            } catch (error) {
                setError(error);
                setIsLoaded(true);
            }
        }

        if (authValue && authValue.user) {
            getData();
        }
    }, [authValue]);

    if (error) {
        return (
            <div className="POIs">
                <Header heading="POI's" />

                <Error error={error} />
            </div>
        )
    } else if (!isLoaded) {
        return (
            <div className="POIs">
                <Header heading="POI's" />

                <div className="flex flex-col max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
                    <div className="bg-gray-300 w-full animate-pulse h-16 rounded-lg mb-4"></div>
                    <div className="bg-gray-300 w-full animate-pulse h-16 rounded-lg"></div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="POIs">
                <Header heading="POI's" />

                <div className="flex flex-col max-w-7xl mx-auto pb-6 px-4 sm:px-6 lg:px-8">
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                            <div className="flex justify-between py-6">
                                <div className="mt-1 relative rounded-md shadow-sm w-1/3">
                                    <input
                                        type="text"
                                        value={filter}
                                        className="block border-gray-300 w-full pr-10 focus:outline-none sm:text-sm rounded-md"
                                        placeholder={`Filter ${POIs.length} POI's`} onInput={(event) => setFilter(event.target.value || '')} />

                                    {filter && <div className="absolute inset-y-0 right-0 pr-2 flex items-center cursor-pointer" onClick={() => setFilter('')}>
                                        <XCircleIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                                    </div>}
                                </div>

                                <Link
                                    to="/pois/new"
                                    className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                                    <PlusIcon className="mr-1 h-5 w-5"></PlusIcon>
                                    New POI
                                </Link>
                            </div>

                            <div className="shadow border-b border-gray-200 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-white">
                                        <tr>
                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Name
                                            </th>

                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Location
                                            </th>

                                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Center
                                            </th>

                                            <th scope="col" className="relative px-6 py-3">
                                                <span className="sr-only">Edit</span>
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {filteredPOIs.map(poi => (
                                            <tr key={poi.id}>
                                                <td className="px-6 py-4 whitespace-nowrap items-center">
                                                    <Link to={`/pois/${poi.id}`} className="underline cursor-pointer">{poi.name}</Link>

                                                    {poi.is_event &&<span className="ml-2 inline-flex flex-shrink-0 items-center px-2 py-0.5 text-gray-800 text-xs font-medium bg-gray-100 rounded-full">
                                                        <CalendarIcon className="w-4 h-4 mr-1" />
                                                        Event
                                                    </span>}
                                                </td>

                                                <td className="flex items-center px-6 py-4 whitespace-nowrap">
                                                    <Link to={`/locations/${poi.geoname_id}`} className="underline cursor-pointer">{poi.location_name}</Link>

                                                    <span className="ml-2 inline-flex flex-shrink-0 items-center px-2 py-0.5 text-gray-800 text-xs font-medium bg-gray-100 rounded-full">
                                                        <GlobeEuropeAfricaIcon className="w-4 h-4 mr-1" />
                                                        {poi.country}
                                                    </span>
                                                </td>

                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <PopoverMap latitude={poi.latitude} longitude={poi.longitude} zoom={poi.zoom} />
                                                </td>

                                                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                    <Link to={`/pois/${poi.id}`} className="underline cursor-pointer text-blue-800 hover:text-blue-900">View</Link>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default POIs;