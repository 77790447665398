import { createContext, useState } from "react";
import ContinentDetail from "../components/continent/ContinentDetail";

export const ContinentContext = createContext({
    continent: {},
    setContinent: () => {}
});

function ContinentWrapper() {
    const [continent, setContinent] = useState({});

    return (
        <ContinentContext.Provider value={[continent, setContinent]}>
            <ContinentDetail />
        </ContinentContext.Provider>
    )
}

export default ContinentWrapper;