import { PlusIcon } from "@heroicons/react/24/outline";
import { useContext, useRef, useState } from "react";
import config from "../../config";
import { getUserToken } from "../../contexts/AuthContext";
import { NotificationContext } from "../../contexts/NotificationContext";
import { RegionContext } from "../../pages/RegionWrapper";
import EditRow from "../EditRow";
import RegionTranslationLine from "./RegionTranslationLine";

function RegionTranslations() {
    const [region, setRegion] = useContext(RegionContext);
    const [editing, setEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState('');
    const [languageCode, setLanguageCode] = useState('');
    const languageCodeRef = useRef(null);
    const { addNotification } = useContext(NotificationContext);

    function addTranslation(event) {
        event.preventDefault();
        addRegionTranslation();
    };

    async function addRegionTranslation() {
        setLoading(true);

        const token = await getUserToken();
        const res = await fetch(`${config.backend_url_v2}/v2/Region/Translation/${region.id}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                name,
                language_code: languageCode
            })
        });

        const result = await res.json();

        if (res.status === 200) {
            const newList = [...region.translations, {
                name,
                language_code: languageCode,
                id: result
            }];

            let copy = {...region};
            copy.translations = newList;

            setRegion(copy);
            setLoading(false);
            setName('');
            setLanguageCode('');
            addNotification('Translation was added successfully', 'SUCCESS');

            // Put focus on the first input so you can keep typing
            if (languageCodeRef.current) {
                languageCodeRef.current.focus();
            }
        } else {
            setLoading(false);
            addNotification(result.message, 'ERROR');
        }
    }

    if (region) {
        return (
            <section className="bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="flex items-center justify-between px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Region name translations</h3>

                    <EditRow
                            cancel={false}
                            buttonText="Done"
                            loading={loading}
                            editState={editing}
                            save={() => { setEditing(false) }}
                            editing={(state) => { setEditing(state) }} />
                </div>

                <div className="border-t border-gray-200">
                    {region.translations.map(translation => (
                        <RegionTranslationLine editing={editing} key={translation.id} translation={translation} />
                    ))}
                </div>

                {editing && <div className="flex flex-col border-t max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-6">
                    <div className="mt-1 flex rounded-md">
                        <form onSubmit={addTranslation} className="flex items-stretch flex-grow focus-within:z-10">
                            <input
                                ref={languageCodeRef}
                                value={languageCode}
                                onChange={(e) => setLanguageCode(e.target.value)}
                                type="text"
                                name="language-code"
                                id="language-code"
                                className="focus:ring-blue-500 focus:border-blue-500 block w-1/6 rounded-none rounded-md sm:text-sm border-gray-300"
                                placeholder="nl or en_US" />

                            <input
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                type="text"
                                name="name"
                                id="name"
                                className="ml-4 focus:ring-blue-500 focus:border-blue-500 block w-full rounded-none rounded-md sm:text-sm border-gray-300"
                                placeholder="Paris or Berlijn" />

                            {(name && languageCode) && <button
                                className="ml-4 inline-flex items-center px-4 py-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600">
                                <span>Add</span>
                                <PlusIcon className="ml-1 w-5 h-5" />
                            </button>}

                            {(!name || !languageCode) && <button
                                disabled
                                className="ml-4 inline-flex items-center px-4 py-1 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-300">
                                <span>Add</span>
                                <PlusIcon className="ml-1 w-5 h-5" />
                            </button>}
                        </form>
                    </div>
                </div>}
            </section>
        )
    } else {
        return null
    }
}

export default RegionTranslations;