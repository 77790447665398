import { sha256 } from "js-sha256";
import config from "../config";
import { generateId } from "./Random";

function hexToBase64(hexstring) {
    return btoa(hexstring.match(/\w{2}/g).map(function(a) {
        return String.fromCharCode(parseInt(a, 16));
    }).join(""));
}

export async function wrapFetch(url) {
    try {
        const hmac = generateHmacToken("GET", url);
        const res = await fetch(`${url}`, {
            headers: {
                "Authorization": `${hmac}`,
            }
        });

        if (res.ok === false) {
            throw new Error(res.statusText);
        }

        const result = await res.json();

        return result;
    } catch (error) {
        return error;
    }
}

export function generateHmacToken(httpMethod, requestUrl) {
    try {
        const timestamp = Math.floor(new Date().getTime() / 1000);
        const nonce = generateId(32);
        const uri = encodeURIComponent(requestUrl).toLowerCase();
        const signatureRawData = `${config.hmac_app_id}${httpMethod}${uri}${timestamp}${nonce}`;

        // Convert string to byte array
        const signatureRawDataBytes = new TextEncoder().encode(signatureRawData);

        // Convert API key to byte array
        const hmacApiKeyBytes = Uint8Array.from(atob(config.hmac_api_key), c => c.charCodeAt(0));

        // Create HMAC
        const hmac = sha256.hmac(hmacApiKeyBytes, signatureRawDataBytes);

        // Encode HMAC signature as base64
        const hmacSignatureBase64 = hexToBase64(hmac);

        return `HMAC ${config.hmac_app_id}:${hmacSignatureBase64}:${nonce}:${timestamp}`;
    } catch (error) {
        console.log(error);
    }
}