import { Fragment, useContext, useEffect, useState } from "react";
import { getUserToken } from "../contexts/AuthContext";
import Header from "../components/Header";
import config from "../config";
import { CircleMarker, MapContainer, TileLayer } from "react-leaflet";
import { Link, useNavigate } from "react-router-dom";
import { CheckIcon } from "@heroicons/react/24/outline";
import { NotificationContext } from "../contexts/NotificationContext";
import { LeafletHandler } from "../util/Map";
import Error from "../components/Error";

function MapNew() {
    const navigate = useNavigate();
    const [error] = useState(false);
    const { addNotification } = useContext(NotificationContext);
    const [center, setCenter] = useState({lat: null, lng: null});
    const [zoom, setZoom] = useState(5);
    const [name, setName] = useState('');

    useEffect(() => {
        document.title = `GeoCMS\u00ae | New Map`;
    }, []);

    async function postData() {
        const token = await getUserToken();
        const data = {
            map_name: name,
            center_latitude: Number(center.lat.toFixed(6)),
            center_longitude: Number(center.lng.toFixed(6)),
            map_zoom: Number(zoom)
        }

        const res = await fetch(`${config.backend_url_v3}/v3/cms/mapdata`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(data)
        });

        if (res.status === 200) {
            addNotification('Map was saved successfully', 'SUCCESS');
            navigate('/maps');
        } else {
            const result = await res.json();
            addNotification(result.message, 'ERROR');
        }
    }

    function submitForm(event) {
        event.preventDefault();
        postData();
    };

    if (error) {
        return (
            <Fragment>
                <Header heading="New Map" />

                <Error error={error} />
            </Fragment>
        )
    } else {
        return (
            <Fragment>
                <Header heading="Add a new map" />

                <div className="flex flex-col max-w-5xl mx-auto pb-6 px-4 sm:px-6 lg:px-8">
                    <form onSubmit={submitForm}>
                        <div className="mt-6 grid grid-cols-12 gap-y-6 gap-x-4">
                            <div className="sm:col-span-6">
                                <label htmlFor="username" className="block text-sm font-medium text-gray-700">
                                    Map name
                                </label>

                                <div className="mt-1 flex rounded-md shadow-sm">
                                    <input
                                        value={name}
                                        onInput={event => setName(event.target.value)}
                                        type="text"
                                        name="poi-name"
                                        id="poi-name"
                                        placeholder="ClientWeatherMap_Netherlands"
                                        className="flex-1 focus:ring-blue-500 focus:border-blue-500 block w-full min-w-0 rounded-md sm:text-sm border-gray-300"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="flex items-center mt-4 m1-2">
                            <h3 className="text-sm leading-6 font-medium text-gray-900">Click on the map to set the center of the new Map and set the wanted zoom</h3>
                        </div>

                        <div className="bg-white shadow overflow-hidden sm:rounded-lg h-half">
                            <MapContainer center={[50, 4]} zoom={5} zoomSnap={0.1} zoomDelta={0.1}>
                                <TileLayer
                                    attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors - &copy; <a href="https://maptiler.com/copyright">MapTiler</a>'
                                    url="https://maps.meteoplaza.com/styles/topo/{z}/{x}/{y}.png"
                                />

                                <LeafletHandler newCenter={(latlng) => setCenter(latlng)} newZoom={(zoom) => setZoom(zoom)} />

                                {center && <CircleMarker
                                    center={center}
                                    pathOptions={{ color: 'black' }}
                                    radius={10}>
                                </CircleMarker>}
                            </MapContainer>
                        </div>

                        {center && center.lat && center.lng && <div className="flex mt-6">
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                Lat {center.lat.toFixed(6)}, Long {center.lng.toFixed(6)}
                            </span>

                            <span className="ml-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                Zoom {zoom}
                            </span>
                        </div>}

                        <div className="pt-5">
                            <div className="flex justify-end">
                                <Link
                                    to='/pois'
                                    className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                                    Cancel
                                </Link>

                                {(name && center && zoom) && <button
                                    type="submit"
                                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                                    <CheckIcon className="h-5 w-5 mr-1" />
                                    Save
                                </button>}

                                {(!name || !center || !zoom) && <button
                                    disabled
                                    className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-200">
                                    <CheckIcon className="h-5 w-5 mr-1" />
                                    Save
                                </button>}
                            </div>
                        </div>
                    </form>
                </div>
            </Fragment>
        )
    }
}

export default MapNew;