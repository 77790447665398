import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, login } from "../contexts/AuthContext";

function Login() {
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();

    useEffect(() => {
        if (loading) {
            // maybe trigger a loading screen
            return;
        }

        if (user) {
            navigate("/");
        }
    }, [user, navigate, loading]);

    useEffect(() => {
        document.title = `GeoCMS\u00ae | Login`;
    }, []);

    return (
        <div className="flex h-screen">
            <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                <div className="mx-auto  max-w-sm lg:w-96">
                    <img
                        className="h-16 w-auto bg-gray-800 rounded-xl p-4"
                        src="/logo-c.svg"
                        alt="Geo CMS" />
                    <h2 className="mt-6 text-3xl font-extrabold text-gray-900">Sign in with your account</h2>

                    <div className="mt-8">
                        <div className="mt-1 grid">
                            <div onClick={login} className="w-full cursor-pointer inline-flex items-center justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                                <svg className="w-10 h-10" aria-hidden="true" fill="currentColor" viewBox="0 0 40 40">
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <rect x="0" y="0" width="40" height="40" rx="2"></rect>
                                        <g transform="translate(10.5, 10.5)">
                                            <path d="M17.64,9.20454545 C17.64,8.56636364 17.5827273,7.95272727  17.4763636,7.36363636 L9,7.36363636 L9,10.845 L13.8436364,10.845 C13.635,11.97 13.0009091,12.9231818 12.0477273,13.5613636 L12.0477273,15.8195455 L14.9563636,15.8195455 C16.6581818,14.2527273 17.64,11.9454545 17.64,9.20454545 L17.64,9.20454545 Z" fill="#4285F4"></path>
                                            <path d="M9,18 C11.43,18 13.4672727,17.1940909 14.9563636,15.8195455 L12.0477273,13.5613636 C11.2418182,14.1013636 10.2109091,14.4204545 9,14.4204545 C6.65590909,14.4204545 4.67181818,12.8372727  3.96409091,10.71 L0.957272727,10.71 L0.957272727,13.0418182  C2.43818182,15.9831818 5.48181818,18 9,18 L9,18 Z" fill="#34A853"></path>
                                            <path d="M3.96409091,10.71 C3.78409091,10.17 3.68181818,9.59318182 3.68181818,9  C3.68181818,8.40681818 3.78409091,7.83 3.96409091,7.29 L3.96409091,4.95818182  L0.957272727,4.95818182 C0.347727273,6.17318182 0,7.54772727 0,9 C0,10.4522727  0.347727273,11.8268182 0.957272727,13.0418182 L3.96409091,10.71 L3.96409091,10.71 Z" fill="#FBBC05"></path>
                                            <path d="M9,3.57954545 C10.3213636,3.57954545 11.5077273,4.03363636 12.4404545,4.92545455  L15.0218182,2.34409091 C13.4631818,0.891818182 11.4259091,0 9,0 C5.48181818,0  2.43818182,2.01681818 0.957272727,4.95818182 L3.96409091,7.29 C4.67181818,5.16272727  6.65590909,3.57954545 9,3.57954545 L9,3.57954545 Z" fill="#EA4335"></path>
                                            <path d="M0,0 L18,0 L18,18 L0,18 L0,0 Z"></path>
                                        </g>
                                    </g>
                                </svg>

                                <span>Sign in with Google</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="hidden lg:block relative w-0 flex-1">
                <picture>
                    <source srcSet="/bg.avif" type="image/avif"/>
                    <source srcSet="/bg.webp" type="image/webp"/>
                    <source srcSet="/bg.jpg" type="image/jpeg"/>
                    <img src="/bg.jpg" alt="" className="absolute inset-0 h-full w-full object-cover" />
                </picture>
            </div>
        </div>
    );
}

export default Login;