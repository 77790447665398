import { Fragment, useEffect, useState } from 'react';
import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import { classNames } from '../util/Format';
import Header from '../components/Header';
import SearchDatabase from '../components/search/SearchDatabase';
import SearchMeilisearch from '../components/search/SearchMeilisearch';

const tabs = [
    { key: 'meili', name: 'Search Meilisearch', component: <SearchMeilisearch /> },
    { key: 'db', name: 'Search Database', component: <SearchDatabase /> },
];

function Search() {
    const { search } = useLocation();
    const navigate = useNavigate();
    const [currentMode, setCurrentMode] = useState(tabs[0]);

    function changeMode(tab) {
        let params = queryString.parse(search);

        params.m = tab.key;
        navigate(`/search?${queryString.stringify(params)}`);

        setCurrentMode(tab);
    }

    useEffect(() => {
        const params = queryString.parse(search);

        if (params.m && params.m.length > 0) {
            const mode = decodeURI(params.m);

            if (mode !== currentMode.key) {
                const newMode = tabs.find((item) => item.key === mode);

                setCurrentMode(newMode);
            }
        }
    }, [search, currentMode]);

    useEffect(() => {
        document.title = `GeoCMS\u00ae | Search`;
    }, []);

    if (currentMode) {
        return (
            <Fragment>
                <Header heading="Search" />

                <div className="flex flex-col max-w-7xl mx-auto px-8 pt-6">
                    <div className="hidden sm:block">
                        <nav className="flex space-x-4">
                            {tabs.map((tab) => (
                                <span
                                    key={tab.name}
                                    onClick={() => changeMode(tab)}
                                    className={classNames(
                                        (tab.key === currentMode.key) ? 'bg-blue-600 text-white' : 'text-gray-500 hover:text-white hover:bg-blue-700',
                                        'px-3 py-2 rounded-md cursor-pointer'
                                    )}
                                >
                                    {tab.name}
                                </span>
                            ))}
                        </nav>
                    </div>
                </div>

               {currentMode.component}
            </Fragment>
        )
    }
}

export default Search;