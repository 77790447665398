import './App.css';
import { Route, Routes } from 'react-router-dom';
import { AuthenticatedRoute } from './components/AuthenticatedRoute';
import { setMetaVersion } from './util/Document';
import Continents from './pages/Continents';
import Countries from './pages/Countries';
import Home from './pages/Home';
import Login from './pages/Login';
import Regions from './pages/Regions';
import Search from './pages/Search';
import RegionWrapper from './pages/RegionWrapper';
import RegionNew from './pages/RegionNew';
import CountryWrapper from './pages/CountryWrapper';
import Admin1Wrapper from './pages/Admin1Wrapper';
import Admins from './pages/Admins';
import LocationWrapper from './pages/LocationWrapper';
import POIs from './pages/POIs';
import POIWrapper from './pages/POIWrapper';
import POINew from './pages/POINew';
import ContinentWrapper from './pages/ContinentWrapper';
import Lists from './pages/Lists';
import ListNew from './pages/ListNew';
import ListWrapper from './pages/ListWrapper';
import Events from './pages/Events';
import EventWrapper from './pages/EventWrapper';
import EventNew from './pages/EventNew';
import Maps from './pages/Maps';
import MapNew from './pages/MapNew';
import MapWrapper from './pages/MapWrapper';

function App() {
  // Sets the current git hash of the app as a meta tag
  setMetaVersion();

  return (
    <main>
      <Routes>
        <Route path='/login' element={<Login />} exact/>

        <Route path='/' element={<AuthenticatedRoute />}>
          <Route path='/' element={<Home/>} exact/>
          <Route path='/search' element={<Search/>}/>
          <Route path='/continents' element={<Continents/>} exact/>
          <Route path='/continents/:id' element={<ContinentWrapper/>} exact/>
          <Route path='/countries' element={<Countries/>} exact/>
          <Route path='/countries/:id' element={<CountryWrapper/>} exact/>
          <Route path='/regions' element={<Regions/>} exact/>
          <Route path='/regions/new' element={<RegionNew/>} exact/>
          <Route path='/regions/:id' element={<RegionWrapper/>} exact/>
          <Route path='/locations/:id' element={<LocationWrapper/>} exact/>
          <Route path='/admins/:id' element={<Admins/>} exact/>
          <Route path='/admin/:id' element={<Admin1Wrapper/>} exact/>
          <Route path='/pois' element={<POIs/>} exact/>
          <Route path='/pois/new' element={<POINew/>} exact/>
          <Route path='/pois/:id' element={<POIWrapper/>} exact/>
          <Route path='/events' element={<Events/>} exact/>
          <Route path='/events/new' element={<EventNew/>} exact/>
          <Route path='/events/:id' element={<EventWrapper/>} exact/>
          <Route path='/lists' element={<Lists/>} exact/>
          <Route path='/lists/new' element={<ListNew/>} exact/>
          <Route path='/lists/:id' element={<ListWrapper/>} exact/>
          <Route path='/maps' element={<Maps/>} exact/>
          <Route path='/maps/new' element={<MapNew />} exact/>
          <Route path='/maps/:id' element={<MapWrapper/>} exact/>
        </Route>
      </Routes>
    </main>
  );
}

export default App;
