import { useContext, useEffect, useState } from "react";
import { PlusIcon } from "@heroicons/react/24/solid";
import { wrapFetch } from "../../util/Hmac";
import config from "../../config";
import { generateId } from "../../util/Random";
import { latinize } from "../../util/Format";
import { getUserToken } from "../../contexts/AuthContext";
import { NotificationContext } from "../../contexts/NotificationContext";
import { RegionContext } from "../../pages/RegionWrapper";

function RegionListEdit() {
    const [region, setRegion] = useContext(RegionContext);
    const [input, setInput] = useState('');
    const [lists, setLists] = useState([]);
    const { addNotification } = useContext(NotificationContext);
    const [filteredLists, setFilteredLists] = useState([]);

    async function addList(listId, regionId) {
        const token = await getUserToken();
        const res = await fetch(`${config.backend_url_v3}/v3/cms/region/list`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                region_id: regionId,
                list_id: listId
            })
        });

        if (res.status === 200) {
            let copy = { ...region };

            const list = lists.find((list) => list.id === listId);

            copy.region_lists.push({
                list_id: listId,
                list_name: list.name_default,
                locations: list.locations
            });

            setRegion(copy);
            addNotification('List was added!', 'SUCCESS');
        }
    }

    async function deleteList(listId, regionId) {
        const token = await getUserToken();
        const res = await fetch(`${config.backend_url_v3}/v3/cms/region/list`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                region_id: regionId,
                list_id: listId
            })
        });

        if (res.status === 200) {
            let copy = { ...region };

            const filteredList = copy.region_lists.filter((item) => item.list_id !== listId);
            copy.region_lists = filteredList;

            setRegion(copy);
            addNotification('List was removed!', 'SUCCESS');
        }
    }

    useEffect(() => {
        setFilteredLists(lists);
    }, [lists]);

    useEffect(() => {
        const newList = lists.filter((list) => latinize(list.name_default).toLowerCase().includes(input.toLowerCase()));
        setFilteredLists(newList);
    }, [lists, input]);

    // Get the full list of lists
    useEffect(() => {
        async function getData() {
            try {
                const result = await wrapFetch(`${config.backend_url_v3}/v3/cms/list?cb=${generateId(6)}`);

                setLists(result);
            } catch (error) {
                console.log(error);
            }
        }

        getData();
    }, []);

    return (
        <div>
            {region.region_lists.length && region.region_lists.map(list => (
                <div key={list.list_id} className="flex justify-between items-center bg-blue-100 mx-6 p-4 my-2 rounded">
                    <span>{list.list_name}</span>

                    <button
                        onClick={() => deleteList(list.list_id, region.id)}
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-300">
                        Remove
                    </button>
                </div>
            ))}

            <form>
                <div className="flex flex-col border mx-6 p-4">
                    <input
                        onInput={event => setInput(event.target.value)}
                        type="search"
                        name="search"
                        id="search"
                        className="focus:ring-blue-500 focus:border-blue-500 block w-full rounded pl-4 sm:text-sm border-gray-300"
                        placeholder="Search for list"
                    />
                </div>
            </form>

            {filteredLists && input.length >= 3 && filteredLists.map(list => (
                <div key={list.id} className="flex justify-between center-items bg-blue-100 mx-6 p-4 my-2 rounded">
                    <span>{list.name_default}</span>
                    <button
                        onClick={() => addList(list.id, region.id)}
                        type="button"
                        className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-500">
                        Add
                        <PlusIcon className="ml-1 h-5 w-5" />
                    </button>
                </div>
            ))}
        </div>
    )
}

export default RegionListEdit;