import { Fragment, useContext, useState } from "react";
import { Link } from "react-router-dom";
import config from "../../config";
import { getUserToken } from "../../contexts/AuthContext";
import { NotificationContext } from "../../contexts/NotificationContext";
import { LocationContext } from "../../pages/LocationWrapper";
import { formatLargeNumber, formatPossibleEmpty, formatPossibleEmptyLink, roundTo } from "../../util/Format";
import EditRow from "../EditRow";
import PopoverText from "../PopoverText";

function LocationDetails() {
    const { addNotification } = useContext(NotificationContext);
    const [location, setLocation] = useContext(LocationContext);
    const [editing, setEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [population, setPopulation] = useState(location.population || 0);
    const [elevation, setElevation] = useState(location.elevation || 0);
    const [score, setScore] = useState(location.score);

    async function postData() {
        setLoading(true);

        const token = await getUserToken();

        if (population !== location.population || elevation !== location.elevation) {
            const data = {
                population: Number(population),
                elevation: Number(elevation)
            }

            const res = await fetch(`${config.backend_url_v2}/v2/Location/Details/${Number(location.geoname_id)}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(data)
            });

            if (res.status === 200) {
                const copy = {...location};
                copy.population = population;
                copy.elevation = elevation;
                setLocation(copy);

                setEditing(false);
                addNotification('Location was editted successfully', 'SUCCESS');
            } else {
                const result = await res.json();
                addNotification(result.message, 'ERROR');
            }
        }

        if (String(score) !== String(location.score)) {
            if (score < 0 || score > 1) {
                addNotification('Please use a score between 0 and 1.', 'ERROR');
            } else {
                const scoreConverted = parseFloat(score);

                const res = await fetch(`${config.backend_url_v2}/v2/Location/Score/${location.geoname_id}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({
                        score: scoreConverted
                    })
                });

                if (res.status === 200) {
                    const copy = {...location};
                    copy.score = scoreConverted;
                    setLocation(copy);

                    addNotification('Score was adjusted', 'SUCCESS');
                } else {
                    const result = await res.json();
                    addNotification(result.message, 'ERROR');
                }
            }
        }

        setEditing(false);
        setLoading(false);
    }

    function showRegions() {
        if (location && location.region && location.region.length > 0) {
            return (
                <div className="bg-white shadow sm:rounded-lg mt-4">
                    <div className="px-4 py-5 sm:px-6">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Location region</h3>
                    </div>

                    <div className="border-t border-gray-200">
                        <dl>
                            {location.region.map((region) => (
                                <div key={region.id} className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">
                                        Region
                                    </dt>

                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        {formatPossibleEmptyLink(`/regions/${region.id}`, region.name_default)}
                                    </dd>
                                </div>
                            ))}
                        </dl>
                    </div>
                </div>
            )
        }
    }

    return (
        <Fragment>
            <div className="bg-white shadow sm:rounded-lg mt-4">
                <div className="flex justify-between px-4 py-5 sm:px-6">
                    <div className="flex items-center">
                        <h3 className="text-lg leading-6 font-medium text-gray-900">Location details</h3>
                    </div>

                    <EditRow
                        cancel={true}
                        loading={loading}
                        editState={editing}
                        save={() => postData()}
                        editing={(state) => setEditing(state)}></EditRow>
                </div>

                <div className="border-t border-gray-200">
                    <dl>
                        <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                                Asciiname
                            </dt>

                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <span>{formatPossibleEmpty(location.ascii_name)}</span>
                            </dd>
                        </div>

                        <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                                Country
                            </dt>

                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <Link to={`/countries/${location.country_id}`} className="underline cursor-pointer text-blue-800 hover:text-blue-900">{location.country_name}</Link>
                            </dd>
                        </div>

                        <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                                Admin 1
                            </dt>

                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {location.admin1}
                            </dd>
                        </div>

                        <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                                Admin 2
                            </dt>

                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                {location.admin2}
                            </dd>
                        </div>

                        <div className="bg-white items-center px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                                Population
                            </dt>

                            {!editing && <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <span>{formatLargeNumber(location.population)}</span>
                            </dd>}

                            {editing && <input
                                value={population}
                                onChange={(e) => setPopulation(e.target.value)}
                                type="text"
                                name="population"
                                id="population"
                                className="focus:ring-blue-500 focus:border-blue-500 block w-full rounded-none rounded-md sm:text-sm border-gray-300"
                                placeholder="123"/>}
                        </div>

                        <div className="bg-white items-center px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                                Elevation (m)
                            </dt>

                            {!editing && <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <span>{location.elevation}</span>
                            </dd>}

                            {editing && <div className="flex">
                                    <input
                                        value={elevation}
                                        onChange={(e) => setElevation(e.target.value)}
                                        type="text"
                                        name="elevation"
                                        id="elevation"
                                        className="inline-flex focus:ring-blue-500 focus:border-blue-500 block flex-1 rounded-none rounded-md sm:text-sm border-gray-300"
                                        placeholder="123"/>
                                </div>}
                        </div>

                        <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                                Timezone
                            </dt>

                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <span>{location.timezone}</span>
                            </dd>
                        </div>

                        <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                                Geonames type
                            </dt>

                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <div className="flex items-center space-x-3 mb-2">
                                    <span className="flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full">
                                        {location.feature_class}
                                    </span>

                                    <span className="flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full">
                                        {location.feature_code}
                                    </span>
                                </div>
                            </dd>
                        </div>

                        <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500 flex items-center">
                                Score

                                <PopoverText text="The location score is one of the factors that determines how high a location is ranked in the search result. It is a number between 0 and 1." />
                            </dt>

                            {!editing && <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <div className="flex items-center space-x-3 mb-2">
                                    <span className="flex-shrink-0 inline-block px-2 py-0.5 text-blue-800 text-xs font-medium bg-blue-100 rounded-full">
                                        {roundTo(score, 3)}
                                    </span>
                                </div>
                            </dd>}

                            {editing && <div className="flex">
                                <input
                                    value={score}
                                    onChange={(e) => setScore(e.target.value)}
                                    type="text"
                                    name="score"
                                    id="score"
                                    className="inline-flex focus:ring-blue-500 focus:border-blue-500 block flex-1 rounded-none rounded-md sm:text-sm border-gray-300"
                                    placeholder="0.5"/>
                            </div>}
                        </div>
                    </dl>
                </div>
            </div>

            {showRegions()}
        </Fragment>
    )
}

export default LocationDetails;