import { useContext } from "react";
import { CircleMarker, MapContainer, TileLayer } from "react-leaflet";
import { useEffect, useState } from "react";
import config from "../../config";
import { getUserToken } from "../../contexts/AuthContext";
import { NotificationContext } from "../../contexts/NotificationContext";
import { roundTo } from "../../util/Format";
import { LeafletHandler } from "../../util/Map";
import EditRow from "../EditRow";
import { LocationContext } from "../../pages/LocationWrapper";
import { Link } from "react-router-dom";

function LocationMap() {
    const [location, setLocation] = useContext(LocationContext);
    const { addNotification } = useContext(NotificationContext);
    const [center, setCenter] = useState({lat: null, lon: null});
    const [zoom, setZoom] = useState(null);
    const [editing, setEditing] = useState(false);

    useEffect(() => {
        setCenter({ lat: location.latitude, lng: location.longitude });
        setZoom(location.zoom);
    }, [location]);

    function changeCenter(latlng) {
        if (editing) {
            setCenter(latlng);
        }
    }

    async function updateLocation() {
        const token = await getUserToken();
        const res = await fetch(`${config.backend_url_v2}/v2/Location/Coordinate/${Number(location.geoname_id)}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                latitude: Number(roundTo(center.lat, 6)),
                longitude: Number(roundTo(center.lng, 6))
            })
        });

        if (res.status === 200) {
            let copy = { ...location };
            copy.latitude = Number(roundTo(center.lat, 6));
            copy.longitude = Number(roundTo(center.lng, 6));
            copy.zoom = zoom;

            setLocation(copy);
            setEditing(false);
            addNotification('Location was editted successfully', 'SUCCESS');
        } else {
            const result = await res.json();
            addNotification(result.message, 'ERROR');
        }
    }

    if (location) {
        return (
            <section className="bg-white shadow overflow-hidden rounded-lg flex full-w flex-col mt-4">
                <div className="flex items-center justify-between px-4 py-5 sm:px-6">
                    <div>
                        <div className="flex items-center">
                            <h2 className="text-lg leading-6 font-medium text-gray-900">
                                Map center
                            </h2>

                            <div className="flex ml-2">
                                <Link to={`/search?m=db&q=${location.latitude},${location.longitude}`} className="ml-4 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 hover:bg-green-200">
                                    Position {roundTo(location.latitude, 6)}, {roundTo(location.longitude, 6)}
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-center">
                        <EditRow
                            cancel={true}
                            buttonText="Save"
                            editState={editing}
                            save={() => { updateLocation() }}
                            editing={(state) => { setEditing(state) }} />
                    </div>
                </div>

                <div className="block h-half z-0">
                    <MapContainer center={[location.latitude, location.longitude]} zoom={13}>
                        <TileLayer
                            attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors - &copy; <a href="https://maptiler.com/copyright">MapTiler</a>'
                            url="https://maps.meteoplaza.com/styles/topo/{z}/{x}/{y}.png" />

                        {center && <CircleMarker
                            center={center}
                            pathOptions={{ color: 'black' }}
                            radius={10}>
                        </CircleMarker>}

                        <LeafletHandler newCenter={(latlng) => changeCenter(latlng)} newZoom={() => {}} />
                    </MapContainer>
                </div>
            </section>
        )
    } else {
        return null;
    }
}

export default LocationMap;