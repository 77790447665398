import { CheckIcon } from "@heroicons/react/24/solid";
import { Link } from "react-router-dom";

export function formatPossibleEmpty(value) {
    if (!value) {
        return 'Unknown';
    } else {
        return value;
    }
}

export function formatPossibleEmptyLink(url, value) {
    if (value) {
        return (
            <Link to={url} className="underline cursor-pointer text-blue-800 hover:text-blue-900">{value}</Link>
        )
    } else {
        return 'Unknown';
    }
}

export function formatLargeNumber(num) {
    if (Number(num) !== 0) {
        return Number(num).toLocaleString(undefined);
    } else {
        return 'Unknown';
    }
}

export function roundTo(value, decimals) {
    return Number(value).toFixed(decimals);
}

export function latinize(str) {
    let result = str.trim().toLowerCase();

    // remove accents, swap ñ for n, etc
    const from = 'āăåàáãäâçḑèéëêīìíïîøõòóöôūùúüûñţýz̧ı';
    const to =   'aaaaaaaacdeeeeiiiiioooooouuuuuntyzi';

    for (let i = 0, l = from.length; i < l; i += 1) {
        result = result.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    return result;
};

export function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export function checkPill(value, text) {
    if (value) {
        return (
            <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                <CheckIcon className="w-4 h-4 mr-1"></CheckIcon>
                {text}
            </span>
        )
    }
}
