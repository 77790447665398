import { useContext } from "react";
import { CircleMarker, MapContainer, TileLayer, GeoJSON } from "react-leaflet";
import { useEffect, useState } from "react";
import config from "../../config";
import { getUserToken } from "../../contexts/AuthContext";
import { NotificationContext } from "../../contexts/NotificationContext";
import { CountryContext } from "../../pages/CountryWrapper";
import { roundTo } from "../../util/Format";
import { LeafletHandler, plotCitiesWithLink } from "../../util/Map";
import EditRow from "../EditRow";

function CountryMap() {
    const [country, setCountry] = useContext(CountryContext);
    const { addNotification } = useContext(NotificationContext);
    const [center, setCenter] = useState({lat: null, lon: null});
    const [zoom, setZoom] = useState(null);
    const [editing, setEditing] = useState(false);
    const [geojson, setGeojson] = useState(null);

    useEffect(() => {
        async function setupMap() {
            setCenter({ lat: country.latitude, lng: country.longitude });
            setZoom(country.zoom);

            if (country.outline_url && !geojson) {
                const response = await fetch(country.outline_url);
                const geojson = await response.json();
                setGeojson(geojson);
            }
        }

        setupMap();
    }, [country, geojson]);

    function changeCenter(latlng) {
        if (editing) {
            setCenter(latlng);
        }
    }

    async function updateCountry() {
        const token = await getUserToken();
        const res = await fetch(`${config.backend_url_v2}/v2/Country/Coordinate/${Number(country.geonames_id)}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                latitude: Number(roundTo(center.lat, 6)),
                longitude: Number(roundTo(center.lng, 6)),
                zoom: Number(roundTo(zoom, 1))
            })
        });

        if (res.status === 200) {
            let copy = { ...country };
            copy.latitude = Number(roundTo(center.lat, 6));
            copy.longitude = Number(roundTo(center.lng, 6));
            copy.zoom = Number(roundTo(zoom, 1));

            setCountry(copy);
            setEditing(false);
            addNotification('Country was editted successfully', 'SUCCESS');
        } else {
            const result = await res.json();
            addNotification(result.message, 'ERROR');
        }
    }

    if (country) {
        return (
            <section className="bg-white shadow overflow-hidden rounded-lg flex full-w flex-col my-6">
                <div className="flex items-center justify-between px-4 py-5 sm:px-6">
                    <div>
                        <div className="flex items-center">
                            <h2 className="text-2xl font-bold text-gray-900">
                                Map center and zoom
                            </h2>

                            <div className="flex ml-4">
                                <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                    Lat {roundTo(center.lat, 6)}, Long {roundTo(center.lng, 6)}
                                </span>

                                {!editing && <span className="ml-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                    Zoom {roundTo(country.zoom, 1)}
                                </span>}

                                {editing && <span className="ml-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                    Zoom {roundTo(zoom, 1)}
                                </span>}
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-center">
                        <EditRow
                            cancel={true}
                            buttonText="Save"
                            editState={editing}
                            save={() => { updateCountry() }}
                            editing={(state) => { setEditing(state) }} />
                    </div>
                </div>

                <div className="block h-half z-0">
                    <MapContainer center={[country.latitude, country.longitude]} zoom={country.zoom} zoomSnap={0.1} zoomDelta={0.1}>
                        <TileLayer
                            attribution='&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors - &copy; <a href="https://maptiler.com/copyright">MapTiler</a>'
                            url="https://maps.meteoplaza.com/styles/topo/{z}/{x}/{y}.png" />

                        {geojson && <GeoJSON
                            pane="tilePane"
                            style={{
                                color: '#686868',
                                fillOpacity: 0.2,
                                weight: 2,
                                bubblingMouseEvents: false
                            }}
                            data={geojson} />}

                        {center && <CircleMarker
                            center={center}
                            pathOptions={{ color: 'black' }}
                            radius={10}>
                        </CircleMarker>}

                        {plotCitiesWithLink(country.cities)}

                        <LeafletHandler newCenter={(latlng) => changeCenter(latlng)} newZoom={(zoom) => setZoom(zoom)} />
                    </MapContainer>
                </div>
            </section>
        )
    } else {
        return null;
    }
}

export default CountryMap;